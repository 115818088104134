import { createEffect, sample } from "effector";
import AuthService from "../../../services/api/auth/AuthService";
import { RegisterConfirmRequestDto } from "@generated/models/RegisterConfirmRequestDto";
import { AxiosError } from "axios";
import { showErrorToast } from "@models/toast";
import { goToRouteFx } from "@models/router";
import { ROUTES } from "../../../constants/ROUTES";

export const registerConfirmFx = createEffect(async (requestBody: RegisterConfirmRequestDto) => {
	const { data } = await AuthService.registerConfirm(requestBody);

	return data;
});

sample({
	clock: registerConfirmFx.failData,
	fn: (error) => {
		if (error instanceof AxiosError) {
			showErrorToast(error.response?.data.message ?? "Неизвестная ошибка");
		}
		goToRouteFx(ROUTES.REGISTRATION);
	},
});
