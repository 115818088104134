import { sample } from "effector";
import { initFooterData } from "@features/Footer/model/public";
import { getDialogOfGenerationsHeadingsFx } from "@models/dialogOfGenerations/getHeadings";
import { getDialogOfGenerationsThemesFx } from "@models/dialogOfGenerations/getThemes";
import { $headings, $themes } from "@features/Footer/model/private";
import { mapDialogOfGenerationsApiList } from "@features/Footer/model/utils";
import { HeadingResponse, ThemeResponse } from "@services/api/dialogOfGenerations/typedef";

sample({
	clock: initFooterData,
	source: $headings,
	filter: (headings) => {
		return headings.length === 0;
	},
	target: getDialogOfGenerationsHeadingsFx,
	fn: () => {
		return {
			page: 1,
		};
	},
});

sample({
	clock: initFooterData,
	source: $themes,
	filter: (themes) => {
		return themes.length === 0;
	},
	target: getDialogOfGenerationsThemesFx,
	fn: () => {
		return {
			page: 1,
		};
	},
});

sample({
	clock: getDialogOfGenerationsThemesFx.doneData,
	fn: (data) => {
		return mapDialogOfGenerationsApiList<ThemeResponse>(data, "themes");
	},
	target: $themes,
});

sample({
	clock: getDialogOfGenerationsHeadingsFx.doneData,
	fn: (data) => {
		return mapDialogOfGenerationsApiList<HeadingResponse>(data, "headings");
	},
	target: $headings,
});
