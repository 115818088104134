import CookiesBanner from "@shared/UI/CookiesBanner";
import React from "react";
import Head from "next/head";
import s from "./styles.module.sass";
import { Header } from "../../features/Header/view/entries/Header";
import Footer from "@features/Footer/view";

interface AppLayoutProps {
	title?: string;
	children: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ title, children }) => {
	return (
		<div className={s.appLayoutWrapper}>
			<Head>
				<meta name="viewport" content="width=device-width, maximum-scale=1" />
				<title>{title || "Selfschool"}</title>
			</Head>
			<Header />
			<main className={s.main}>{children}</main>
			<CookiesBanner />
			<Footer />
		</div>
	);
};

export default AppLayout;
