import { createEffect, createEvent, sample } from "effector";
import { AxiosError } from "axios";
import CategoriesService from "../../../services/api/categories/CategoriesService";
import { $allCategories } from "@models/categories/public";
import { GetAllCategoriesParams } from "@models/categories/getAllCategories/typedef";

import { CategoriesResponse, GetCategoriesParams } from "src/services/api/categories/typedef";
import { ErrorResponse } from "@interfaces/error";

export const getAllCategoriesEvent = createEvent<GetAllCategoriesParams>();

export const getAllCategoriesFx = createEffect<
	GetCategoriesParams,
	CategoriesResponse["items"],
	AxiosError<ErrorResponse>
>(async ({ withCoursesOnly }) => {
	const { data } = await CategoriesService.getCategories({
		withCoursesOnly,
	});

	return data.items;
});

sample({
	clock: getAllCategoriesEvent,
	target: getAllCategoriesFx,
});

sample({
	clock: getAllCategoriesFx.doneData,
	target: $allCategories,
});
