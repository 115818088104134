import { restorePasswordFx } from "@models/auth/restorePassword";
import { $authProcess, updateAuthProcess } from "../public";

$authProcess.on(restorePasswordFx.doneData, (store) => {
	updateAuthProcess({
		stage: "restorePasswordSuccess",
		restorePasswordStage: {
			confirmationEmailCounter: store.restorePasswordStage.confirmationEmailCounter + 1,
		},
	});
});
