import { createEffect, createEvent, sample } from "effector";
import UserService from "../../../services/api/user/UserService";
import { AddCourseToFavouriteParams } from "src/services/api/user/typedef";

export const addCourseToFavouritesEvent = createEvent<AddCourseToFavouriteParams>();

export const addCourseToFavouritesFx = createEffect(async (requestBody: AddCourseToFavouriteParams) => {
	const { data } = await UserService.addToFavourites(requestBody);

	return data;
});

sample({
	clock: addCourseToFavouritesEvent,
	target: addCourseToFavouritesFx,
});
