import { CategoriesResponse } from "src/services/api/categories/typedef";

export const mapCategoriesWithAllCategory = (
	categories: CategoriesResponse["items"]
): CategoriesResponse["items"] => {
	return [
		{
			id: 0,
			title: "Все категории",
			url: "all-courses",
			themes: [],
		},
		...categories,
	];
};
