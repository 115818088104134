import s from "./styles.module.sass";
import { ROUTES } from "../../../../../constants/ROUTES";
import Image from "next/image";
import favouritesSrc from "../../../../../../public/icons/favourites-white.svg";
import NextLink from "@shared/wrappers/NextLink";
import { createStoreConsumer } from "effector-react";
import { $favouriteCourses } from "../../../model/public";

const FavoriteIconStoreConsumer = createStoreConsumer($favouriteCourses);

const FavoriteIcon = () => {
	return (
		<FavoriteIconStoreConsumer>
			{(favoriteCourses) => {
				const favoriteCoursesCount = favoriteCourses?.items?.length;
				return (
					<div className={s.favorites}>
						<NextLink href={ROUTES.MY_EDUCATION_FAVOURITES}>
							<Image src={favouritesSrc} layout="fill" objectFit="contain" alt="Избранное" />
							{favoriteCoursesCount !== 0 && <div className={s.favoritesCounter}>{favoriteCoursesCount}</div>}
						</NextLink>
					</div>
				);
			}}
		</FavoriteIconStoreConsumer>
	);
};

export default FavoriteIcon;
