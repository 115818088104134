import styles from "./Header.module.sass";
import React, { useEffect } from "react";
import { MobileHeader } from "../MobileHeader";
import { DesktopHeader } from "../DesktopHeader";
import { useEvent, useStore, useStoreMap } from "effector-react";
import { USER_STATUSES } from "src/constants/statuses";
import clsx from "clsx";
import { ROUTES } from "src/constants/ROUTES";
import { useRouter } from "next/router";
import { HeaderProps } from "../../Header.typedef";
import { $allCategories } from "@models/categories/public";
import { mapCategoriesWithAllCategory } from "@models/categories/mappers/mapCategoriesWithAllCategory";
import { $searchValue, setSearchValue } from "src/features/Header/model/private";
import "../../../model/init";
import { $authedUser } from "@features/Auth/model/public";
import { $isAuth } from "../../../../../process/authProcess/model/public";

export const Header = () => {
	const { push, query } = useRouter();

	const categories = useStoreMap($allCategories, (categories) => {
		return mapCategoriesWithAllCategory(categories);
	});
	const isUserAuth = useStore($isAuth);
	const authedUser = useStore($authedUser);
	const searchValue = useStore($searchValue);
	const setSearchValueEvent = useEvent(setSearchValue);
	const onChangeSearchValue = (value: string) => {
		setSearchValueEvent(value);
	};

	const onSearchFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (searchValue.trim().length < 1) {
			return;
		}

		push(`${ROUTES.SEARCH}?value=${searchValue}`);
	};

	const isUserDeleted = authedUser?.status === USER_STATUSES.DELETED;

	const headerProps: HeaderProps = {
		categories: categories || [],
		searchValue: searchValue,
		onChangeSearchValue: onChangeSearchValue,
		onSearchFormSubmit: onSearchFormSubmit,
	};

	useEffect(() => {
		setSearchValueEvent(query.value?.toString() || "");
	}, []);

	return (
		<header>
			<div className={clsx(styles.header, styles.desktopHeaderWrapper)}>
				<DesktopHeader {...headerProps} isAuth={isUserAuth} isUserDeleted={isUserDeleted} />
			</div>

			<div className={clsx(styles.header, styles.mobileHeaderWrapper)}>
				<MobileHeader {...headerProps} />
			</div>
		</header>
	);
};
