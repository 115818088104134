import { useStore } from "effector-react";
import {
	$shoppingCart,
	addToCartNonAuthed,
	getCartForUnAuthedUser,
	removeFromCartAuthed,
	removeFromCartNonAuthed,
	resetShoppingCart,
} from "@features/ShoppingChart/model/public";
import { useEvent } from "effector-react/scope";
import { addCourseToCartFx } from "@models/shoppingCart/addCourseToCart";
import { getCartFx, getCartLocalCourseFx } from "@models/shoppingCart/getCart";
import { useMemo } from "react";
import { ProductId } from "@features/ShoppingChart/model/typedef";

type UseShoppingCartParams = {
	isAuth: boolean;
};

type HandleAddToCart = (productId: ProductId) => void;

type HandleRemoveFromCart = (product: ProductId) => void;

/**
 * Хук является прослойкой между view моделью корзины и эффектором
 */
export const useShoppingCart = ({ isAuth }: UseShoppingCartParams) => {
	const shoppingCart = useStore($shoppingCart);
	const getCart = useEvent(getCartFx);
	const getCartLocalCourse = useEvent(getCartForUnAuthedUser);
	const addToCartAuthedFx = useEvent(addCourseToCartFx);
	const addToCartNonAuthedEvent = useEvent(addToCartNonAuthed);
	const removeFromCartAuthedEvent = useEvent(removeFromCartAuthed);
	const removeFromCartNonAuthedEvent = useEvent(removeFromCartNonAuthed);
	const resetShoppingCartEvent = useEvent(resetShoppingCart);

	const cartProductsAmount = shoppingCart?.courses?.length ?? 0;

	const authStatus = isAuth ? "isAuth" : "isNonAuth";

	/**
	 * Логика работы с корзиной построена на статусе авторизации пользователя
	 * в зависимости от статуса авторизации при удалении/добавлении товаров
	 * в корзину необходимо выполнять различные действия
	 */
	const AUTH_STATUS_HANDLERS_MAP = useMemo(() => {
		return {
			isAuth: {
				addToCartHandler: addToCartAuthedFx,
				removeFromCartHandler: removeFromCartAuthedEvent,
				handleGetCart: getCartFx,
			},
			isNonAuth: {
				addToCartHandler: addToCartNonAuthedEvent,
				removeFromCartHandler: removeFromCartNonAuthedEvent,
				handleGetCart: getCartLocalCourseFx,
			},
		};
	}, [addToCartAuthedFx, removeFromCartAuthedEvent, addToCartNonAuthedEvent, removeFromCartNonAuthedEvent]);

	const handleAddToCart: HandleAddToCart = (productId) => {
		const handler = AUTH_STATUS_HANDLERS_MAP[authStatus]["addToCartHandler"];
		handler(productId);
	};

	const handleRemoveFromCart: HandleRemoveFromCart = (productId) => {
		const handler = AUTH_STATUS_HANDLERS_MAP[authStatus]["removeFromCartHandler"];
		handler(productId);
	};

	const handleGetCart = () => {
		getCart();
	};

	const handleGetCartForUnAuthedUser = (coursesIds: Array<ProductId>) => {
		return getCartLocalCourse(coursesIds);
	};

	return {
		handleAddToCart,
		handleRemoveFromCart,
		shoppingCart,
		cartProductsAmount,
		resetShoppingCartEvent,
		handleGetCart,
		handleGetCartForUnAuthedUser,
	};
};
