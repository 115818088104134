import { createEffect, createEvent, sample } from "effector";
import { AxiosError } from "axios";
import { ServerResponse } from "http";
import UserService from "../../../services/api/user/UserService";
import { $authedUser } from "@features/Auth/model/public";
import { AuthUserResponse } from "../../../services/api/user/typedef";
import { $profileInfo } from "@components/views/SettingsView/PersonalSettingsView/model/private";
import { initialProfileInfo } from "@components/views/SettingsView/PersonalSettingsView/model/utils";

export const getCurrentUserAccountInfo = createEvent();

export const getCurrentUserAccountInfoFx = createEffect<void, AuthUserResponse, AxiosError<ServerResponse>>(
	async () => {
		const { data } = await UserService.getCurrentUserAccountInfo();

		return data;
	}
);

sample({
	clock: getCurrentUserAccountInfo,
	target: getCurrentUserAccountInfoFx,
});

sample({
	clock: getCurrentUserAccountInfoFx.doneData,
	target: $authedUser,
});

sample({
	clock: getCurrentUserAccountInfoFx.doneData,
	fn: (data) => {
		if (data.profile) return data.profile;
		return initialProfileInfo;
	},
	target: $profileInfo,
});
