import { createDomain, createEffect } from "effector";
import ProfileService from "../../../../../services/api/user/ProfileService";
import {
	ProfileError,
	ProfileInfoField,
} from "@components/views/SettingsView/PersonalSettingsView/model/typedef";
import { initialProfileInfo } from "@components/views/SettingsView/PersonalSettingsView/model/utils";
import { ProfileChangePhotoBody, ProfileInfo } from "../../../../../services/api/user/typedef";

export const profileSettings = createDomain("profile-settings-view");

export const $profileInfo = profileSettings.store<ProfileInfo>(initialProfileInfo);
export const $profileError = profileSettings.store<ProfileError | null>(null);

export const submitUpdateProfileEvent = profileSettings.event<ProfileInfo>();

export const updateProfileFieldEvent = profileSettings.event<{
	field: ProfileInfoField;
	value: ProfileInfo[ProfileInfoField];
}>();

export const updateProfilePhotoEvent = profileSettings.event<ProfileChangePhotoBody>();

export const updateProfileInfoFx = createEffect<[ProfileInfo, ProfileError | null], ProfileInfo>(
	async ([profile, validationError]) => {
		if (validationError !== null) throw new Error();

		const { bio, site, firstName, lastName, competence } = profile;
		const { data } = await ProfileService.updateProfileInfo({
			bio,
			site: site,
			firstName,
			lastName,
			competence,
		});

		const mappedProfile: ProfileInfo = {
			...data,
			site: data.site,
			photo: profile.photo,
		};

		return mappedProfile;
	}
);
