import clsx from "clsx";
import React, { FC } from "react";
import s from "./styles.module.sass";

interface ILinearProgress {
	variant?: "outlined";
	isBorderPulse?: boolean;
	progress: number;
}

const LinearProgress: FC<ILinearProgress> = ({ variant, isBorderPulse, progress }) => {
	return (
		<div
			className={clsx(s.path, {
				[s.outlined]: variant === "outlined",
				[s.borderPulse]: isBorderPulse,
			})}
		>
			<div
				className={s.trail}
				style={{
					width: `${progress}%`,
				}}
			/>
		</div>
	);
};

export default LinearProgress;
