import React from "react";
import styles from "./styles.module.css";
import { FooterMenuColumn } from "@features/Footer/view/parts/FooterMenuColumn";
import { DIALOG_OF_GENERATIONS_URL } from "@config/index";
import Contacts from "@features/Footer/view/parts/Contacts";
import FooterLogo from "public/icons/logo_footer.svg";
import Image from "next/image";

type Item = {
	label: string;
	url: string;
};

export const dialogGenerationColumnItems: Item[] = [
	{ label: "Диалоги", url: `${DIALOG_OF_GENERATIONS_URL}/dialogs` },
	{ label: "Мнения", url: `${DIALOG_OF_GENERATIONS_URL}/opinions` },
	{ label: "Статьи", url: `${DIALOG_OF_GENERATIONS_URL}/articles` },
	{ label: "Подкасты", url: `${DIALOG_OF_GENERATIONS_URL}/podcasts` },
];

export const libraryColumnItems: Item[] = [
	{ label: "О Библиотеке знаний", url: `/about-us` },
	{ label: "Курсы", url: `/all-courses` },
	{ label: "Условия сотрудничества", url: `/terms-of-cooperation` },
	{ label: "Гайд для авторов", url: `/docs/guide_for_authors.pdf` },
	{ label: "Гайд для пользователей", url: `/docs/guide_for_users.pdf` },
	{ label: "Гайд для учеников", url: `/docs/guide_for_students.pdf` },
	{ label: "Часто задаваемые вопросы", url: `/faq` },
];

const FooterInfo = () => {
	return (
		<div className={styles.container}>
			<div className={styles.gridContainer}>
				<div style={{ width: "124px", height: "30px" }}>
					<Image src={FooterLogo} alt="Selfschool" />
				</div>
				<div className={styles.footerMenuColumns}>
					<FooterMenuColumn title={"Диалог поколений"} items={dialogGenerationColumnItems} />
					<FooterMenuColumn title={"Библиотека знаний"} items={libraryColumnItems}></FooterMenuColumn>
				</div>

				<Contacts />
			</div>
		</div>
	);
};

export default FooterInfo;
