export const DOCUMENT_LINKS = {
	USER_AGREEMENT_URL: "/docs/user_agreement.pdf",
	PRIVACY_URL: "/docs/privacy.pdf",
	COURSE_PUBLICATION_RULES_URL: "/docs/course_publication_rules.pdf",
	GUIDE_FOR_AUTHORS: "/docs/guide_for_authors.pdf",
	GUIDE_FOR_USERS: "/docs/guide_for_users.pdf",
	GUIDE_FOR_STUDENTS: "/docs/guide_for_students.pdf",
	PERSONAL_DATA_AGREEMENT: "/docs/personal_data_agreement.pdf",
	COOKIES_URL: "/docs/cookie.pdf",
};
