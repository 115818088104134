export const routes = {
	notifications: "/notifications",
	auth: "/auth",
	collections: "/collections",
	login: "/[intent]",
	categories: "/categories",
	courses: "/courses",
	course: "/course",
	progress: "/progress",
	billing: "/billing",
	sections: "/sections",
	quizzes: "/quizzes",
	tests: "/tests",
	lectures: "/lectures",
	suggestions: "/suggestions",
	usersMe: "/users/me",
	users: "/users",
	profileUrl: "/profiles/me",
	usersMeCreatedCourses: "/users/me/created-courses",
	files: "/files",
	cart: "/cart",
	notes: "/notes",
	purchasedCourses: "/purchased-courses",
} as const;

export const billingRoutes = {
	addCard: `${routes.billing}/card`,
	billingHistory: `${routes.billing}/history`,
	cashOut: `${routes.billing}/cashout`,
};

export const dialogOfGenerationsRoutes = {
	headings: "/dialog/headings/list",
	themes: "/dialog/themes/list",
};

export const bitrixBlogRoutes = {
	listing: "/blog",
} as const;
