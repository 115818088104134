import useIsFirstRender from "@hooks/useIsFirstRender";
import { useLayoutEffect } from "react";
import { deleteCookie } from "cookies-next";
import { SHOPPING_CART_COOKIE_KEY } from "@features/ShoppingChart/model/constants";
import { useShoppingCart } from "@features/ShoppingChart/hooks/useShoppingCart";
import { getShoppingCartIdsArr } from "@features/ShoppingChart/model/utils";

type UseShoppingCartLifecycleProps = { isAuth: boolean };
type UseShoppingCartLifecycle = (props: UseShoppingCartLifecycleProps) => void;

/**
 * Хук управляет жизненным циклом корзины товаров
 */
export const useShoppingCartLifecycle: UseShoppingCartLifecycle = ({ isAuth }) => {
	const isFirstRender = useIsFirstRender();
	const { handleGetCart, handleAddToCart, handleGetCartForUnAuthedUser } = useShoppingCart({
		isAuth,
	});

	/**
	 * Если пользователь зашел в приложение неавторизованным и добавил покупки
	 * в корзину, то при его логине необходимо синхронизировать список
	 * его покупок с состоянием его корзины в бд
	 */
	useLayoutEffect(() => {
		if (!isFirstRender && isAuth) {
			const shoppingCartIdsArr = getShoppingCartIdsArr();
			if (shoppingCartIdsArr.length) {
				handleAddToCart(shoppingCartIdsArr[0]);
				deleteCookie(SHOPPING_CART_COOKIE_KEY);
				return;
			}
			handleGetCart();
		}
	}, [isAuth]);

	/**
	 * При инициализации приложения необходимо запросить список товаров в корзине
	 * Если пользователь авторизован - делаем запрос на получение корзины
	 * если нет, получаем список id покупок из куки
	 * если он не пуст, делаем запрос на получения соотв покупок
	 */
	useLayoutEffect(() => {
		if (isAuth) {
			handleGetCart();
		} else {
			const shoppingCartIdsArr = getShoppingCartIdsArr();
			if (shoppingCartIdsArr.length) {
				handleGetCartForUnAuthedUser(shoppingCartIdsArr);
			}
		}
	}, []);
};
