import { createEffect } from "effector";
import CoursesService from "../../../services/api/courses/CoursesService";
import { GetCoursesByCoursesIdsParams, GetCoursesByIdsResponse } from "src/services/api/courses/typedef";
import { AxiosError } from "axios";
import { ErrorResponse } from "@interfaces/error";

export const getCoursesByCoursesIdsFx = createEffect<
	GetCoursesByCoursesIdsParams,
	GetCoursesByIdsResponse,
	AxiosError<ErrorResponse>
>(async (coursesIds) => {
	const { data } = await CoursesService.getCoursesByCoursesIds(coursesIds);

	return data;
});
