import { createEffect } from "effector";
import { AxiosError } from "axios";
import { ErrorResponse } from "@interfaces/error";
import PurchasedCoursesService from "@services/purchasedCourses";
import { PurchasedCoursesList } from "@services/purchasedCourses/typedef";

export const getPurchasedCoursesFx = createEffect<number, PurchasedCoursesList, AxiosError<ErrorResponse>>(
	async (page) => {
		const { data } = await PurchasedCoursesService.getPurchasedCourses(page);
		return data;
	}
);
