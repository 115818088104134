import { attach } from "effector";
import { addCourseToFavouritesFx } from "@models/users/addCourseToFavourites";
import { removeCourseFromFavouritesFx } from "@models/users/removeCourseFromFavourites";

export const addCourseToFavouriteFx = attach({
	effect: addCourseToFavouritesFx,
});

export const removeCourseFromFavouriteFx = attach({
	effect: removeCourseFromFavouritesFx,
});
