import { $api } from "../index";
import { AxiosResponse } from "axios";
import { routes } from "../routes";
import {
	RegisterConfirmRequestDto,
	AuthChallengeResponseDto,
	OauthConfirmRequestDto,
	PhoneRequestDto,
	PhoneConfirmRequestDto,
	OauthRequestDto,
	LoginRequestDto,
	RegisterRequestDto,
	RestorePasswordRequestDto,
	RestorePasswordConfirmRequestDto,
} from "@generated/index";

interface RegisterResponse {
	status: number;
	message: string;
}

type ConfirmPhoneNumberResponse = {
	requirements: Array<string>;
	challenge: string;
};

class AuthService {
	static async useChallenge(params: { challenge: string }) {
		return await $api.post<void>(`${routes.auth}/use-challenge`, params);
	}

	static async refresh() {
		return await $api.post<void>(`${routes.auth}/refresh`);
	}

	static async login({ email, password }: LoginRequestDto) {
		const requestBody = {
			email: email.toLowerCase(),
			password,
		};

		return await $api.post<
			Omit<AuthChallengeResponseDto, "requirements"> & {
				requirements: Array<string>;
			}
		>(`${routes.auth}/login`, requestBody);
	}

	static async oauth(requestBody: OauthRequestDto) {
		return $api.post<{ url: string }>(`${routes.auth}/oauth`, requestBody);
	}

	static async oauthConfirm(requestBody: OauthConfirmRequestDto) {
		return $api.post<Omit<AuthChallengeResponseDto, "requirements"> & { requirements: Array<string> }>(
			`${routes.auth}/oauth/confirm`,
			requestBody
		);
	}

	static async oauthConfirmRegister(requestBody: OauthConfirmRequestDto) {
		return $api.post<Omit<AuthChallengeResponseDto, "requirements"> & { requirements: Array<string> }>(
			`${routes.auth}/oauth/confirm-register`,
			requestBody
		);
	}

	static async addPhoneNumber(requestBody: PhoneRequestDto) {
		return $api.post<void>(`${routes.auth}/phone`, requestBody);
	}

	static async confirmPhone(
		requestBody: PhoneConfirmRequestDto
	): Promise<AxiosResponse<ConfirmPhoneNumberResponse>> {
		return $api.post<ConfirmPhoneNumberResponse>(`${routes.auth}/phone/confirm`, requestBody);
	}

	static async register(requestBody: RegisterRequestDto): Promise<AxiosResponse<RegisterResponse>> {
		return await $api.post<RegisterResponse>(`${routes.auth}/register`, requestBody);
	}

	static async registerConfirm(requestBody: RegisterConfirmRequestDto) {
		return $api.post<Omit<AuthChallengeResponseDto, "requirements"> & { requirements: Array<string> }>(
			`${routes.auth}/register/confirm`,
			requestBody
		);
	}

	static async restorePassword(requestBody: RestorePasswordRequestDto) {
		return $api.post<{ email: string }>(`${routes.auth}/password/restore`, requestBody);
	}

	static async restorePasswordConfirm(requestBody: RestorePasswordConfirmRequestDto) {
		return $api.post<void>(`${routes.auth}/password/restore/confirm`, requestBody);
	}

	static async logout() {
		return $api.post<void>(`${routes.auth}/logout`);
	}
}

export default AuthService;
