import { createEvent } from "effector";
import { AuthProcess } from "./typedef";
import { root } from "../../../root";
import { AUTH_PROCESS_DEFAULT_STATE } from "./constants";

export const authenticationPublicApi = root.domain("authentication");

export const $isAuth = authenticationPublicApi.store<boolean>(false);
export const $authProcess = authenticationPublicApi.store<AuthProcess>(AUTH_PROCESS_DEFAULT_STATE);

export const updateAuthProcess = createEvent<Partial<AuthProcess>>();

export const restorePassword = createEvent<{ email: string }>();

export const restorePasswordConfirm = createEvent<{ challenge: string; password: string }>();

export const setIsAuth = authenticationPublicApi.event<boolean>();
