import { $api } from "@services/api";
import { routes } from "@services/api/routes";
import { PurchasedCoursesList } from "./typedef";

class PurchasedCoursesService {
	static async getPurchasedCourses(page: number) {
		return await $api.get<PurchasedCoursesList>(`${routes.purchasedCourses}?page=${page}`);
	}
}

export default PurchasedCoursesService;
