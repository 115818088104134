import { useRouter } from "next/router";
import { useEvent } from "effector-react/scope";
import { attachRouterEv } from "@models/router";
import React, { useEffect } from "react";

const RouterProvider = () => {
	const router = useRouter();
	const attachRouter = useEvent(attachRouterEv);
	useEffect(() => {
		attachRouter(router);
	}, [router, attachRouter]);

	return <></>;
};

export default RouterProvider;
