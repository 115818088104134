import { createEffect } from "effector";
import CartService from "src/services/api/user/CartService";
import { AxiosError } from "axios";
import { CartWithCoursesResponse } from "src/services/api/user/typedef";

export const removeCourseFromCartFx = createEffect<number, CartWithCoursesResponse, AxiosError>(
	async (courseId: number) => {
		const { data } = await CartService.removeFromCart(courseId);
		return data;
	}
);
