export enum ROUTES {
	MAIN = "/",
	BLOG = "/blog",
	CONFIRM_PHONE = "/confirm-phone",
	LOGIN = "/login",
	REGISTRATION = "/registration",
	RESTORE_ACCESS = "/restore-access",
	TERMS_OF_COOPERATION = "/terms-of-cooperation",
	MY_EDUCATION_COURSES = "/account/my-education?tab=courses",
	MY_EDUCATION_FAVOURITES = "/account/my-education?tab=favourites",
	CART = "/cart",
	CONTACTS = "/contacts",
	FAQ = "/faq",
	ABOUT_US = "/about-us",
	SEARCH = "/search",
	ALL_COURSES = "/all-courses",
	INSTRUCTOR = "/instructor",
	ACCOUNT_SETTINGS = "/account/settings",
	ACCOUNT_SETTINGS_PERSONAL = "/account/settings/personal",
	ACCOUNT_SETTINGS_PAYMENT = "/account/settings/payment",
	ACCOUNT_SETTINGS_SECURITY = "/account/settings/security",
	CONSTRUCTOR = "/account/constructor",
	INSTRUCTOR_PANEL_COURSES = "/account/instructor-panel/courses",
	INSTRUCTOR_PANEL_INTERACTION = "/account/instructor-panel/interaction",
	INSTRUCTOR_PANEL_FINANCE = "/account/instructor-panel/finance",
	INSTRUCTOR_PANEL_HELP = "/account/instructor-panel/help",
}
