import { sample } from "effector";
import { addPhone, addPhoneAgain } from "@features/Phone/model/public";
import { $authProcess } from "../public";
import { addPhoneNumberFx } from "@models/auth/addPhoneNumber";

sample({
	clock: [addPhone, addPhoneAgain],
	source: $authProcess,
	fn: (store) => {
		return {
			challenge: store.challenge ?? "",
			phone: store.confirmPhoneStage.phone ? `7${store.confirmPhoneStage.phone}` : "",
		};
	},
	target: addPhoneNumberFx,
});

sample({
	clock: addPhoneAgain,
	source: $authProcess,
	fn: (store) => {
		return {
			...store,
			addPhoneStage: {
				sendCodeCounter: store.addPhoneStage.sendCodeCounter + 1,
			},
		};
	},
	target: $authProcess,
});

sample({
	clock: addPhoneNumberFx.doneData,
	source: $authProcess,
	fn: (store) => {
		const nextStage = "verifyPhone" as const;

		return {
			...store,
			stage: nextStage,
		};
	},
	target: $authProcess,
});
