import { sample } from "effector";
import { oauthConfirmFx } from "@models/auth/oauthConfirm";
import { checkAuthRequirementsFx } from "../private";
import { AxiosError } from "axios";
import { showErrorToast } from "@models/toast";
import { goToRouteFx } from "@models/router";
import { ROUTES } from "../../../../constants/ROUTES";

sample({
	clock: oauthConfirmFx.doneData,
	target: checkAuthRequirementsFx,
});

sample({
	clock: oauthConfirmFx.failData,
	fn: (error) => {
		if (
			error instanceof AxiosError &&
			error.response?.data.statusCode === 404 &&
			error.response?.data.message === "user not found"
		) {
			goToRouteFx(ROUTES.REGISTRATION);
			return "Пользователь не найден, зарегестрируйтесь";
		}

		return "";
	},
	target: showErrorToast,
});
