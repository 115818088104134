import { FC, useEffect } from "react";
import Script from "next/script";
import { useRouter } from "next/router";

import { YANDEX_METRICA_ID } from "@config/index";
import { useWebAnalyticsContext, WebAnalyticsContext } from "@shared/analytics/webAnalytics.contexts";
import { createYandexMetrikaClient } from "@shared/analytics/yandexMetrika";

const WebAnalytics = ({ children }: { children: JSX.Element }) => {
	const PageViewObserver: FC = () => {
		const router = useRouter();
		const WebAnalyticsMetrikaContext = useWebAnalyticsContext();

		useEffect(() => {
			const routerRouteChangeComplete = (url: string) => {
				WebAnalyticsMetrikaContext.pageView(new URL(window.location.origin + url));
			};

			router.events.on("routeChangeComplete", routerRouteChangeComplete);

			return () => {
				router.events.off("routeChangeComplete", routerRouteChangeComplete);
			};
		}, []);
		return <></>;
	};

	return (
		<>
			<Script
				strategy={"afterInteractive"}
				id="yandex-webvisor"
				src={"https://mc.yandex.ru/metrika/tag.js"}
			/>
			<WebAnalyticsContext.Provider value={createYandexMetrikaClient(Number(YANDEX_METRICA_ID) ?? "0")}>
				<PageViewObserver />
				{children}
			</WebAnalyticsContext.Provider>
		</>
	);
};
export default WebAnalytics;
