import { sample } from "effector";
import { $authProcess, $isAuth, setIsAuth, updateAuthProcess } from "./public";
import { logoutFx } from "@models/auth/logout";
import "../model/login";
import "../model/registration";
import "../model/oauthLogin";
import "../model/addPhone";
import "../model/verifyPhone";
import "../model/oauthRegister";
import "../model/resoreAccess";
import "../model/resoreAccessConfirm";
import { resetAuthProcess } from "./private";
import { ROUTES } from "../../../constants/ROUTES";
import { goToRouteFx } from "@models/router";

sample({
	clock: updateAuthProcess,
	source: $authProcess,
	fn: (store, data) => {
		return {
			...store,
			...data,
		};
	},
	target: $authProcess,
});

sample({
	clock: setIsAuth,
	target: $isAuth,
});

sample({
	clock: logoutFx.done,
	fn: () => {
		goToRouteFx(ROUTES.MAIN);
		return false;
	},
	target: $isAuth,
});

$authProcess.reset(resetAuthProcess);
