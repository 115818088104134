import { createEffect, createEvent, createStore } from "effector";
import { AxiosError } from "axios";
import { ServerResponse } from "http";
import { addCourseToFavouriteFx, removeCourseFromFavouriteFx } from "./private";
import { CourseList, CoursesList } from "../../../services/api/courses/typedef";
import { COURSES_LIMIT } from "../../../constants/common";

export const $favouriteCourses = createStore<CoursesList | null>(null);
export const $favouriteCoursesLimit = createStore<number>(COURSES_LIMIT);

export const toggleFavouriteCourse = createEvent<{ id: number }>();

export const toggleFavouriteCourseFx = createEffect<
	{ favCourses: CourseList[]; favCourseId: number },
	void,
	AxiosError<ServerResponse>
>(async ({ favCourses, favCourseId }) => {
	const isFavedCourse = !!favCourses.find((course) => {
		return course.id === favCourseId;
	});

	const requestParams = {
		courseId: favCourseId,
	};

	if (isFavedCourse) {
		await removeCourseFromFavouriteFx(requestParams);
		return;
	}

	await addCourseToFavouriteFx(requestParams);
});
