import React, { useLayoutEffect } from "react";
import { useEvent } from "effector-react/scope";
import { getCookie } from "cookies-next";
import { ACCESS_TOKEN_KEY } from "../../process/authProcess/model/constants";
import { useShoppingCartLifecycle } from "@features/ShoppingChart/hooks/useShoppingCartLifecycle";
import { getCurrentUserAccountInfo } from "@models/users/getCurrentUserAccountInfo";
import { getAllCategoriesEvent } from "@models/categories/getAllCategories";
import { setIsAuth } from "../../process/authProcess/model/public";
import { getFavouriteCoursesFx } from "@models/users/getFavouriteCourses";
/**
 * TODO Удалить лишний функционал-обертки, касающиеся авторизации
 * https://github.com/spiks/selfschool-front/issues/1238
 */

export const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
	const accessToken = getCookie(ACCESS_TOKEN_KEY);
	const getFavoriteCourses = useEvent(getFavouriteCoursesFx);
	const getAllCategories = useEvent(getAllCategoriesEvent);

	const getCurrentUserInfoEvent = useEvent(getCurrentUserAccountInfo);

	useShoppingCartLifecycle({
		isAuth: Boolean(accessToken),
	});

	const setIsAuthEvent = useEvent(setIsAuth);

	useLayoutEffect(() => {
		setIsAuthEvent(!!accessToken);
	});

	useLayoutEffect(() => {
		if (accessToken) {
			getCurrentUserInfoEvent();
			getFavoriteCourses();
		}
	}, [accessToken]);

	useLayoutEffect(() => {
		Promise.all([
			getAllCategories({
				withCoursesOnly: true,
			}),
		]);
	}, []);

	return <>{children}</>;
};
