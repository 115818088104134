import { attach, createEffect } from "effector";
import CartService from "src/services/api/user/CartService";
import { getCoursesByCoursesIdsFx } from "@models/courses/getCoursesByCoursesIds";
import { GetCoursesByCoursesIdsParams } from "@services/api/courses/typedef";

export const getCartFx = createEffect(async () => {
	const { data } = await CartService.getCart();
	return data;
});

export const getCartLocalCourseFx = attach({
	effect: getCoursesByCoursesIdsFx,
	mapParams: (ids: number[]): GetCoursesByCoursesIdsParams => {
		return {
			coursesIds: ids.map((id) => {
				return id.toString();
			}),
		};
	},
});
