import { confirmPhoneNumberFx } from "@models/auth/confirmPhoneNumber";
import { sendConfirmationCode } from "@features/Phone/model/public";
import { sample } from "effector";
import { $authProcess } from "../public";
import { showErrorToast } from "@models/toast";
import { AxiosError } from "axios";
import { useChallengeFx } from "@models/auth/useChallenge";

sample({
	clock: sendConfirmationCode,
	source: $authProcess,
	fn: (store) => {
		return {
			challenge: store.challenge ?? "",
			code: store.verifyPhoneStage.verificationCode ?? "",
		};
	},
	target: confirmPhoneNumberFx,
});

sample({
	clock: confirmPhoneNumberFx.doneData,
	fn: (data) => {
		return {
			challenge: data.challenge,
		};
	},
	target: useChallengeFx,
});

sample({
	clock: confirmPhoneNumberFx.failData,
	fn: (error) => {
		if (
			error instanceof AxiosError &&
			error.response?.data.statusCode === 400 &&
			error.response?.data.message === "Confirmation code is incorrect"
		) {
			return "Введен некорректный номер телефона";
		}

		return "Ошибка при подтвержении номера телефона";
	},
	target: showErrorToast,
});
