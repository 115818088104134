import { useStore } from "effector-react";
import Avatar from "@components/UI/Avatar";
import Loader from "../../shared/UI/Loader";
import { ROUTES } from "../../constants/ROUTES";
import { useInstructor } from "@hooks/useInstructor";
import s from "./styles.module.sass";
import { useEvent } from "effector-react/scope";
import { getInitials } from "@shared/functions/getInitials";
import NextLink from "@shared/wrappers/NextLink";
import dynamic from "next/dynamic";
import React from "react";
import { $authedUser } from "../Auth/model/public";
import { logoutFx } from "@models/auth/logout";
import { resetShoppingCart } from "@features/ShoppingChart/model/public";
import { getCurrentUserAccountInfoFx } from "@models/users/getCurrentUserAccountInfo";
import { $profileInfo } from "@components/views/SettingsView/PersonalSettingsView/model/private";
import { DIALOG_OF_GENERATIONS_URL } from "@config/index";

const Popover = dynamic(
	() => {
		return import("@components/UI/Popover");
	},
	{
		ssr: false,
	}
);

const AvatarWithAccountInfo = () => {
	const authedUser = useStore($authedUser);
	const profile = useStore($profileInfo);
	const isLoading = useStore(getCurrentUserAccountInfoFx.pending);

	const logoutEvent = useEvent(logoutFx);
	const resetShoppingCartEvent = useEvent(resetShoppingCart);

	const handleLogout = () => {
		resetShoppingCartEvent();
		logoutEvent();
	};

	const isInstructor = useInstructor();

	const initials = getInitials(profile?.firstName, profile?.lastName);

	const accountUrls = [
		{
			label: "ДИАЛОГ ПОКОЛЕНИЙ",
			url: DIALOG_OF_GENERATIONS_URL || "/",
			id: "dialog",
		},
		{
			label: "Настройка профиля",
			url: ROUTES.ACCOUNT_SETTINGS_PERSONAL,
			id: "settings",
		},
		{
			label: isInstructor ? "Панель преподавателя" : "Стать преподавателем",
			url: ROUTES.INSTRUCTOR_PANEL_COURSES,
			id: "instructor",
		},
	] as const;

	return (
		<div className={s.accountWrapper}>
			<button className={s.accountButton}>
				{isLoading ? (
					<Loader />
				) : (
					<Avatar withBorder size="medium" photo={profile?.photo} initials={initials} />
				)}
			</button>
			<div className={s.popoverWrapper}>
				<Popover>
					<div className={s.accountPopover}>
						<div className={s.accountPopoverHeader}>
							<NextLink href={ROUTES.ACCOUNT_SETTINGS_PERSONAL}>
								<Avatar size="large" photo={profile?.photo} initials={initials} />
							</NextLink>
							<div className={s.accountInfo}>
								<p className={s.accountName}>
									{profile?.firstName} {profile?.lastName}
								</p>
								<p className={s.accountEmail}>{authedUser?.email}</p>
							</div>
						</div>
						<div className={s.accountPopoverMenu}>
							{accountUrls.map(({ label, url, id }) => {
								return (
									<NextLink key={id} href={url} target={id === "dialog" ? "_blank" : undefined}>
										{label}
									</NextLink>
								);
							})}
							<button className={s.logoutButton} onClick={handleLogout}>
								Выйти
							</button>
						</div>
					</div>
				</Popover>
			</div>
		</div>
	);
};

export default AvatarWithAccountInfo;
