import { createContext, useContext } from "react";
import { WebAnalytics } from "./webAnalytics";

export const WebAnalyticsContext = createContext<WebAnalytics | undefined>(undefined);

export const useWebAnalyticsContext = (): WebAnalytics => {
	const context = useContext(WebAnalyticsContext);

	if (context === undefined) {
		throw new Error(
			"Web analytics context is not defined. `useWebAnalyticsContext` should be called within <WebAnalyticsContext.Provider>"
		);
	}

	return context;
};
