import { useEffect, useState } from "react";

export const LOCALSTORAGE_COOKIE_KEY = "cookieAccepted";

export const useAcceptCookie = () => {
	const [isCookieAccepted, setIsCookieAccepted] = useState(true);
	const acceptCookie = () => {
		localStorage.setItem(LOCALSTORAGE_COOKIE_KEY, "true");
		setIsCookieAccepted(true);
	};

	useEffect(() => {
		if (localStorage.getItem(LOCALSTORAGE_COOKIE_KEY) === "true") setIsCookieAccepted(true);
		else setIsCookieAccepted(false);
	}, []);

	return {
		isCookieAccepted,
		acceptCookie,
	};
};
