import Image from "next/image";
import { FC } from "react";
import s from "./styles.module.sass";
import loaderPrimarySrc from "../../../../public/icons/loaderPrimary.svg";
import loaderWhiteSrc from "../../../../public/icons/loaderWhite.svg";
import clsx from "clsx";
import LinearProgress from "@components/UI/LinearProgress";

type Loader = {
	color?: "primary" | "secondary";
	size?: "large" | "medium" | "small";
	variant?: "content" | "window";
	children?: JSX.Element;
	type?: "progress" | "circle";
	progress?: number;
};

const Loader: FC<Loader> = ({
	color = "primary",
	size = "small",
	variant,
	children,
	type = "circle",
	progress,
}) => {
	const renderCircleLoader = () => {
		return (
			<div
				className={clsx(s.loader, {
					[s.small]: size === "small",
					[s.medium]: size === "medium",
					[s.large]: size === "large" || variant === "window",
				})}
			>
				<Image
					src={color === "primary" ? loaderPrimarySrc : loaderWhiteSrc}
					layout="fill"
					objectFit="contain"
					alt="Загрузка"
				/>
			</div>
		);
	};

	// const filledPercent: number = Math.floor((current / total) * 100)
	const renderProgressLoader = () => {
		return (
			<div className={s.linearProgressWrapper}>
				<LinearProgress isBorderPulse variant="outlined" progress={progress || 0} />
				<div className={s.linearProgressPercents}>{progress}%</div>
			</div>
		);
	};
	return (
		<div
			className={clsx(s.loaderBlock, {
				[s.window]: variant === "window",
			})}
		>
			<div className={s.loaderWrapper}>
				{type === "circle" && renderCircleLoader()}
				{type === "progress" && renderProgressLoader()}
			</div>
			{children && <div className={s.loaderMessages}>{children}</div>}
		</div>
	);
};

export default Loader;
