import { createEffect, createEvent } from "effector";
import { goToRouteFx } from "@models/router";
import { ROUTES } from "../../../constants/ROUTES";
import { setIsAuth, updateAuthProcess } from "./public";
import { useChallengeFx } from "@models/auth/useChallenge";
import { AuthChallengeResponseViewModel } from "../../../services/api/auth/typedef";

export const resetAuthProcess = createEvent();

/**
 * При различных флоу авторизации api вместе с challenge присылаем Array - requirements
 * в котором могут быть различные дополнительные требования для авторизациия
 * на данный момент в requirements может быть только 'phone' требование для подтверждения номера
 * телефона
 * Эффект проверяет, если requirements пустой, разрешаем юзеру выполнить логин
 * иначе провеяем необходимые требования в requirements и выполняем соответствующие им действия
 */

export const checkAuthRequirementsFx = createEffect<AuthChallengeResponseViewModel, void>(
	async ({ challenge, requirements }) => {
		if (!requirements.length) {
			await useChallengeFx({
				challenge,
			});
			return;
		}

		for (const requirement of requirements) {
			switch (requirement) {
				case "phone":
					updateAuthProcess({
						challenge,
						stage: "confirmPhone",
					});
					goToRouteFx(ROUTES.CONFIRM_PHONE);
					break;
				default:
					return;
			}
		}
	}
);

/**
 * Эффект определяет необходимую логику действий после успешной авторизации
 * - для флоу авторизации, после успешной авторизации необходимо редиректнуть юзера на главную страницу
 * - для флоу регистрации, после успешной регистрации необходимо показать юзеру экран - "успешная регистрация"
 */
export const callUseChallengeFX = createEffect<{ challengeFlow: "login" | "registration" }, void>(
	async ({ challengeFlow }) => {
		if (challengeFlow === "login") {
			goToRouteFx("/");
			setIsAuth(true);
			return;
		}

		if (challengeFlow === "registration") {
			setIsAuth(true);
			updateAuthProcess({
				stage: "registrationSuccess",
			});
			return;
		}
	}
);
