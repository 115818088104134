import { $api } from "../index";
import { routes } from "../routes";
import { CategoriesResponse, GetCategoriesParams, ThemesResponse } from "./typedef";

class CategoriesService {
	static async getCategories({ withCoursesOnly }: GetCategoriesParams) {
		return await $api.get<CategoriesResponse>(`${routes.categories}?withCoursesOnly=${withCoursesOnly}`);
	}

	static async getThemesByCategoryId(id: number) {
		return await $api.get<ThemesResponse>(`${routes.categories}/${id}/themes`);
	}
}

export default CategoriesService;
