import styles from "./styles.module.css";
import React, { FC, ReactElement } from "react";
import { checkIsLinkInObject } from "@shared/functions/checkIsLinkInObject";
import { DOCUMENT_LINKS } from "src/constants/DOCUMENTS_LINKS";
import { DIALOG_OF_GENERATIONS_URL } from "@config/index";
import NextLink from "@shared/wrappers/NextLink";

type Item = {
	label: string;
	url: string;
};

type Props = {
	items: Item[];
	title?: string | ReactElement;
};

export const FooterMenuColumn: FC<Props> = ({ title, items }) => {
	return (
		<div className={styles.footerMenuColumn}>
			{title && <span className={styles.title}>{title}</span>}
			<div className={styles.linksContainer}>
				{items.map((it) => {
					const linkAnchor =
						checkIsLinkInObject(DOCUMENT_LINKS, it.url) ||
						it.url.includes(DIALOG_OF_GENERATIONS_URL as string)
							? "_blank"
							: undefined;

					if (linkAnchor)
						return (
							<a target={linkAnchor} href={it.url} key={it.label} className={styles.link}>
								{it.label}
							</a>
						);

					return (
						<NextLink target={linkAnchor} className={styles.link} key={it.label} href={it.url}>
							{it.label}
						</NextLink>
					);
				})}
			</div>
		</div>
	);
};
