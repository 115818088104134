import { sample } from "effector";
import { checkAuthRequirementsFx } from "../private";
import { oauthConfirmRegisterFx } from "@models/auth/oauthConfirmRegister";
import { AxiosError } from "axios";
import { goToRouteFx } from "@models/router";
import { ROUTES } from "../../../../constants/ROUTES";
import { showErrorToast } from "@models/toast";

sample({
	clock: oauthConfirmRegisterFx.doneData,
	target: checkAuthRequirementsFx,
});

sample({
	clock: oauthConfirmRegisterFx.failData,
	fn: (error) => {
		if (
			error instanceof AxiosError &&
			error.response?.data.statusCode === 400 &&
			error.response?.data.message === "user with this service is exists"
		) {
			goToRouteFx(ROUTES.LOGIN);
			return "Пользователь с таким email уже существует";
		}

		return "";
	},
	target: showErrorToast,
});
