import React from "react";
import "../model/init";
import FooterInfo from "@features/Footer/view/parts/FooterInfo";

import FooterRights from "@features/Footer/view/parts/FooterRights";

const Footer = () => {
	return (
		<>
			<FooterInfo />
			<FooterRights />
		</>
	);
};

export default Footer;
