import s from "./styles.module.sass";
import Image from "next/image";
import searchSrc from "../../../../../../public/icons/search-white.svg";

export type SearchProps = {
	value: string;
	maxLength?: number;
	onChange: (value: string) => void;
	onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
};

const DesktopSearch = ({ value, maxLength, onChange, onSubmit }: SearchProps) => {
	return (
		<form autoComplete="off" className={s.form} onSubmit={onSubmit}>
			<div className={s.inputWrapper}>
				<input
					className={s.input}
					value={value}
					type="text"
					placeholder="Поиск по сайту..."
					maxLength={maxLength}
					onChange={(e) => {
						return onChange(e.target.value);
					}}
				/>
				<button type="submit" className={s.searchIconWrapper}>
					<Image src={searchSrc} alt="Поиск" className={s.searchIcon} />
				</button>
			</div>
		</form>
	);
};

DesktopSearch.displayName = "Search";

export default DesktopSearch;
