import { sample } from "effector";
import { deleteCookie, setCookie } from "cookies-next";
import { SHOPPING_CART_COOKIE_KEY } from "@features/ShoppingChart/model/constants";
import { removeCourseFromCartFx } from "@models/shoppingCart/removeCourseFromCart";
import { getCartFx, getCartLocalCourseFx } from "@models/shoppingCart/getCart";
import {
	$shoppingCart,
	addToCartNonAuthed,
	getCartForUnAuthedUser,
	removeFromCartAuthed,
	removeFromCartNonAuthed,
	resetShoppingCart,
} from "@features/ShoppingChart/model/public";
import { ProductId } from "@features/ShoppingChart/model/typedef";
import { cartIdCoursesToShoppingCartMapper } from "@features/ShoppingChart/model/utils";
import { addCourseToCartFx } from "@models/shoppingCart/addCourseToCart";

sample({
	clock: getCartForUnAuthedUser,
	target: getCartLocalCourseFx,
});

/**
 *  Флоу НЕ авторизованного пользователя
 */
sample({
	clock: addToCartNonAuthed,
	fn: (productId: ProductId) => {
		setCookie(SHOPPING_CART_COOKIE_KEY, [productId]);
	},
});

sample({
	clock: removeFromCartNonAuthed,
	fn: () => {
		deleteCookie(SHOPPING_CART_COOKIE_KEY);
	},
});

/**
 *  Флоу авторизованного пользователя
 */

sample({
	clock: removeFromCartAuthed,
	target: removeCourseFromCartFx,
});

$shoppingCart
	.on(getCartFx.doneData, (_, payload) => {
		return payload;
	})
	.on(getCartLocalCourseFx.doneData, (_, payload) => {
		return cartIdCoursesToShoppingCartMapper(payload);
	})
	.on(addCourseToCartFx.doneData, (_, payload) => {
		return payload;
	})
	.on(removeCourseFromCartFx.doneData, () => {
		return null;
	})
	.on(removeFromCartNonAuthed, () => {
		return null;
	})
	.reset(resetShoppingCart);
