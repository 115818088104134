import { sample } from "effector";
import { $purchasedCourses, getPurchasedCourses, getPurchasedCoursesFx } from "./public";
import { updatePurchasedCoursesPage, $page } from "./private";
import { PurchasedCoursesResponseDto } from "@generated/models/PurchasedCoursesResponseDto";

sample({
	clock: updatePurchasedCoursesPage,
	target: $page,
});

sample({
	clock: updatePurchasedCoursesPage,
	target: getPurchasedCourses,
});

sample({
	clock: getPurchasedCourses,
	source: $page,
	target: getPurchasedCoursesFx,
});

sample({
	clock: getPurchasedCoursesFx.doneData,
	source: $purchasedCourses,
	target: $purchasedCourses,
	fn: (state, payload) => {
		if (state !== null) {
			const mergedArray = state.items
				.concat(payload.items)
				.reduce<PurchasedCoursesResponseDto["items"]>((accumulator, obj) => {
					const isCourseNotIncluded = !accumulator.some((item) => {
						return item.id === obj.id;
					});
					if (isCourseNotIncluded) {
						accumulator.push(obj);
					}
					return accumulator;
				}, []);
			return {
				...payload,
				items: mergedArray,
			};
		}

		return payload;
	},
});
