import Link from "next/link";
import styles from "./styles.module.css";
import React from "react";
import { useWebAnalyticsContext } from "src/shared/analytics/webAnalytics.contexts";
import VkIcon from "public/icons/vk.svg";
import TgIcon from "public/icons/tg.svg";
import IgIcon from "public/icons/ig.svg";
import Image from "next/image";

type Props = {
	icon: string;
	url: string;
	name: string;
	onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

export const socialLinksUrl = {
	vk: "https://vk.com/selfschoolru",
	telegram: "https://t.me/selfschoolru",
	instagram: "https://www.instagram.com/selfschool.ru/",
};

export const SocialLink = ({ icon, url, name, onClick }: Props) => {
	return (
		<a target="_blank" key={url} href={url} data-name={name} onClick={onClick} rel="noreferrer">
			<Image src={icon} alt={name} />
		</a>
	);
};

const Contacts = () => {
	const { reachGoal } = useWebAnalyticsContext();

	const reachGoalHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		const target = e.currentTarget;
		const linkName = target.getAttribute("data-name");

		reachGoal(`${linkName}-new`);
	};

	return (
		<div className={styles.container}>
			<div className={styles.contacts}>
				<Link href={`/contacts`}>Контакты</Link>
				<Link href={`/contacts`}>Сотрудничество</Link>
			</div>
			<div className={styles.socialLinks}>
				<SocialLink icon={VkIcon} url={socialLinksUrl["vk"]} name="vk" onClick={reachGoalHandler} />
				<div className={styles.forbiddenWeb}>
					<SocialLink icon={IgIcon} url={socialLinksUrl["instagram"]} name="inst" onClick={reachGoalHandler} />
					<div className={styles.popup}>Instagram запрещен на территории РФ</div>
				</div>

			</div>
		</div>
	);
};

export default Contacts;
