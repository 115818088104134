import { ShoppingCart } from "./typedef";
import { getCookie } from "cookies-next";
import { SHOPPING_CART_COOKIE_KEY } from "@features/ShoppingChart/model/constants";
import { GetCoursesByIdsResponse } from "@services/api/courses/typedef";
import { calcFinalPrice } from "@shared/functions/calcFinalPrice";
import { roundNumber } from "@shared/functions/roundNumber";

export const getShoppingCartTotalPrice = (data: ShoppingCart | null) => {
	let total = 0;
	let cost = 0;

	data &&
		data.courses &&
		data.courses.forEach((item) => {
			total += roundNumber(item.cost);
			cost += calcFinalPrice(item);
		});
	return {
		total,
		cost,
	};
};

export const getShoppingCartIdsArr = (): Array<number> => {
	const cookie = getCookie(SHOPPING_CART_COOKIE_KEY);
	const shoppingCartIdsArr = typeof cookie === "string" ? JSON.parse(cookie) : [];

	return shoppingCartIdsArr;
};

// тип курсов в корзине не совпадает с тем, что мы получаем через get /courses
export const cartIdCoursesToShoppingCartMapper = (data: GetCoursesByIdsResponse): ShoppingCart => {
	const mappedCourses = data.map((course) => {
		return {
			...course,
			cost: course.cost,
			finalCost: calcFinalPrice(course),
		};
	});

	return {
		id: NaN,
		...data,
		courses: mappedCourses,
	};
};
