import { attach, createEvent, createStore } from "effector";
import { getPurchasedCoursesFx as apiGetPurchasedCoursesFx } from "@models/users/getPurchasedCourses";
import { PurchasedCoursesList } from "@services/purchasedCourses/typedef";

export const $purchasedCourses = createStore<PurchasedCoursesList | null>(null);

export const getPurchasedCourses = createEvent<void>();

export const getPurchasedCoursesFx = attach({
	effect: apiGetPurchasedCoursesFx,
});
