import { $api } from "../index";
import { routes } from "../routes";
import {
	UpdateProfileBody,
	GetProfileResponse,
	ProfileChangePhotoBody,
	ProfilePhotoUrlResponse,
} from "./typedef";

class ProfileService {
	static async updateProfileInfo(data: UpdateProfileBody) {
		return await $api.put<GetProfileResponse>(`${routes.profileUrl}`, data);
	}

	static async changeProfilePhoto(body: ProfileChangePhotoBody) {
		return await $api.put<ProfilePhotoUrlResponse>(`${routes.profileUrl}/photo`, body);
	}
}

export default ProfileService;
