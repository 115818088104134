import searchSrc from "../../../../../../public/icons/search-white.svg";
import closeBlackSrc from "../../../../../../public/icons/closeBlack.svg";
import styles from "./MobileSearch.module.sass";
import Image from "next/image";
import clsx from "clsx";
import { FormEvent, useEffect, useRef, useState } from "react";

type MobileSearchProps = {
	value: string;
	onChange: (value: string) => void;
	onSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

const MobileSearch = ({ value, onChange, onSubmit }: MobileSearchProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);

	const onOpen = () => {
		setIsOpen(true);
	};

	useEffect(() => {
		if (isOpen && inputRef.current !== null) {
			inputRef.current.focus();
		}
	}, [isOpen]);

	const onClose = () => {
		setIsOpen(false);
	};

	const submitHandler = (e: FormEvent<HTMLFormElement>) => {
		onSubmit(e);
		onClose();
	};

	return (
		<>
			<button onClick={onOpen} className={styles.lensIcon}>
				<Image src={searchSrc} width={20} height={20} alt="Поиск" />
			</button>
			<div
				className={clsx(styles.searchFormWrapper, {
					[styles.opened]: isOpen,
				})}
			>
				<form className={styles.searchForm} autoComplete="off" onSubmit={submitHandler}>
					<input
						className={styles.mobileSearchInput}
						value={value}
						type="text"
						placeholder="Введите запрос"
						onChange={(e) => {
							return onChange(e.target.value);
						}}
						ref={inputRef}
					/>
					<button className={styles.closeButton} onClick={onClose} type="button">
						<Image src={closeBlackSrc} width={15} height={15} alt="Закрыть" />
					</button>
				</form>
			</div>
		</>
	);
};

MobileSearch.displayName = "MobileSearch";

export default MobileSearch;
