import { createEffect, sample } from "effector";
import { AxiosError } from "axios";
import AuthService from "../../../services/api/auth/AuthService";
import { RestorePasswordConfirmRequestDto } from "@generated/models/RestorePasswordConfirmRequestDto";
import { showErrorToast } from "@models/toast";

export const restorePasswordConfirmFx = createEffect<RestorePasswordConfirmRequestDto, void, AxiosError<any>>(
	async (requestBody) => {
		const { data } = await AuthService.restorePasswordConfirm(requestBody);
		return data;
	}
);

sample({
	clock: restorePasswordConfirmFx.failData,
	fn: (error) => {
		if (error instanceof AxiosError) {
			showErrorToast("Не удалось поменять пароль, попробуйте позже");
		}
	},
});
