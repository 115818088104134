import { createEvent, sample } from "effector";
import { CourseList } from "src/services/api/courses/typedef";
import { showErrorToast } from "@models/toast";
import {
	$favouriteCourses,
	$favouriteCoursesLimit,
	toggleFavouriteCourse,
	toggleFavouriteCourseFx,
} from "./public";
import { addCourseToFavouriteFx, removeCourseFromFavouriteFx } from "./private";
import { getFavouriteCoursesFx } from "@models/users/getFavouriteCourses";
export const getFavouriteCoursesEvent = createEvent<number>();

export const updateFavouriteCoursesLimitEvent = createEvent<number>();

sample({
	clock: [getFavouriteCoursesFx.doneData],
	target: $favouriteCourses,
});

sample({
	clock: [addCourseToFavouriteFx.doneData, removeCourseFromFavouriteFx.doneData],
	fn: (courses) => {
		return {
			items: courses.courses,
			totalPage: 1,
			currentPage: 1,
			totalCount: 1,
		};
	},
	target: $favouriteCourses,
});

sample({
	clock: toggleFavouriteCourse,
	source: $favouriteCourses,
	fn: (courses, { id }): { favCourses: CourseList[]; favCourseId: number } => {
		return {
			favCourses: courses ? courses.items : [],
			favCourseId: id,
		};
	},
	target: toggleFavouriteCourseFx,
});

sample({
	clock: toggleFavouriteCourseFx.fail,
	fn: () => {
		return "Произошла ошибка во время добавления курса в избранное";
	},
	target: showErrorToast,
});

sample({
	clock: getFavouriteCoursesEvent,
	target: getFavouriteCoursesFx,
});

sample({
	clock: updateFavouriteCoursesLimitEvent,
	target: $favouriteCoursesLimit,
});

sample({
	clock: updateFavouriteCoursesLimitEvent,
	target: getFavouriteCoursesFx,
});
