import { sample } from "effector";
import { registerConfirmFx } from "@models/auth/activateToken";
import { callUseChallengeFX, checkAuthRequirementsFx } from "../private";
import { useChallengeFx } from "@models/auth/useChallenge";
import { AxiosError } from "axios";
import { goToRouteFx } from "@models/router";
import { ROUTES } from "../../../../constants/ROUTES";
import { showErrorToast } from "@models/toast";
import { updateAuthProcess } from "../public";
import { registerFx } from "@models/auth/register";

sample({
	clock: registerConfirmFx.doneData,
	target: checkAuthRequirementsFx,
});

sample({
	clock: useChallengeFx.done,
	fn: () => {
		const challengeFlow = "registration" as const;
		return {
			challengeFlow,
		};
	},
	target: callUseChallengeFX,
});

registerFx.done.watch(() => {
	updateAuthProcess({
		stage: "registrationCheckEmail",
	});
});

sample({
	clock: registerConfirmFx.failData,
	fn: (error) => {
		if (
			error instanceof AxiosError &&
			error.response?.data.statusCode === 400 &&
			error.response?.data.message === "user_is_already_exist"
		) {
			goToRouteFx(ROUTES.LOGIN);
			return "Пользователь с таким email уже существует";
		}

		return "";
	},
	target: showErrorToast,
});
