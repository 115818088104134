import axios from "axios";
import { API_URL, BITRIX_API_URL } from "../../config";
import { logoutFx } from "@models/auth/logout";
import AuthService from "@services/api/auth/AuthService";

export const $api = axios.create({
	withCredentials: true,
	baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
	return config;
});

let isRetry: boolean;

$api.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const { response, config } = error;
		const { status } = response;
		if (status === 401 && !isRetry) {
			isRetry = true;

			try {
				await AuthService.refresh();
				return $api.request(config);
			} catch {
				await logoutFx();
			}
		}
		throw error;
	}
);

export const $bitrixApi = axios.create({
	baseURL: BITRIX_API_URL,
});
