import { ROUTES } from "../../../constants/ROUTES";
import { DOCUMENT_LINKS } from "../../../constants/DOCUMENTS_LINKS";
import vkIcon from "../../../../public/images/vkIcon.png";
import tgIcon from "../../../../public/images/tgIcon.png";
import instIcon from "../../../../public/images/instIcon.png";
import {
	BaseDialogOfGenerationsListItem,
	DialogOfGenerationPath,
	FooterListItem,
	SocialNetworkLink,
} from "@features/Footer/model/typedef";
import { DialogOfGenerationsListResponse } from "@services/api/dialogOfGenerations/typedef";
import { DIALOG_OF_GENERATIONS_URL } from "@config/index";

export const mapDialogOfGenerationsApiList = <T extends BaseDialogOfGenerationsListItem>(
	list: DialogOfGenerationsListResponse<T>,
	path: DialogOfGenerationPath
): Array<FooterListItem> => {
	return list.items.map((item) => {
		return {
			src: `${DIALOG_OF_GENERATIONS_URL}/${path}/${item.id}`,
			label: item.title,
		};
	});
};

export const footerLinksLeft: Array<FooterListItem> = [
	{
		label: "Часто задаваемые вопросы",
		src: ROUTES.FAQ,
	},
	{
		label: "О Библиотеке знаний",
		src: ROUTES.ABOUT_US,
	},
	{
		label: "Условия сотрудничества",
		src: ROUTES.TERMS_OF_COOPERATION,
	},
	{
		label: "Гайд для авторов",
		src: DOCUMENT_LINKS.GUIDE_FOR_AUTHORS,
	},
	{
		label: "Гайд для пользователей",
		src: DOCUMENT_LINKS.GUIDE_FOR_USERS,
	},
	{
		label: "Гайд для учеников",
		src: DOCUMENT_LINKS.GUIDE_FOR_STUDENTS,
	},
];

export const footerLinksRight: Array<FooterListItem> = [
	{
		label: "Контакты",
		src: ROUTES.CONTACTS,
	},
	{
		label: "Пользовательское соглашение",
		src: DOCUMENT_LINKS.USER_AGREEMENT_URL,
	},
	{
		label: "Политика обработки персональных данных",
		src: DOCUMENT_LINKS.PRIVACY_URL,
	},
	{
		label: "Согласие на обработку персональных данных",
		src: DOCUMENT_LINKS.PERSONAL_DATA_AGREEMENT,
	},
	{
		label: "Согласие на использование cookies-файлов",
		src: DOCUMENT_LINKS.COOKIES_URL,
	},
];

export const footerSocialNetworks: Array<SocialNetworkLink> = [
	{
		label: "vk",
		iconSrc: vkIcon.src,
		src: "https://vk.com/selfschoolru",
	},
	{
		label: "telegram",
		iconSrc: tgIcon.src,
		src: "https://t.me/selfschoolru",
	},
	{
		label: "instagram",
		iconSrc: instIcon.src,
		src: "https://www.instagram.com/selfschool.ru/",
	},
];

export enum SectionsEnum {
	library = "library",
	dialog = "dialog",
	about = "about",
}

export const defaultExpandedSections = [SectionsEnum.library, SectionsEnum.dialog];
