import { createEffect } from "effector";
import { AxiosError } from "axios";
import AuthService from "../../../services/api/auth/AuthService";
import { RestorePasswordRequestDto } from "@generated/models/RestorePasswordRequestDto";

export const restorePasswordFx = createEffect<RestorePasswordRequestDto, { email: string }, AxiosError<any>>(
	async (requestBody) => {
		const { data } = await AuthService.restorePassword(requestBody);
		return data;
	}
);
