import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { FC } from "react";
import s from "./styles.module.sass";
import { defineUserAgent } from "@shared/functions/defineUserAgent";

interface IAvatar {
	initials?: string;
	photo?: any;
	link?: string;
	size?: "small" | "medium" | "large" | "xlarge" | "xxlarge";
	withBorder?: boolean;
}

const Avatar: FC<IAvatar> = ({ initials, link, photo, size = "small", withBorder }) => {
	const userAgent = defineUserAgent();
	const isSafari = userAgent === "Safari";

	const renderAvatar = () => {
		return (
			<div
				className={clsx(s.avatarWrapper, {
					[s.medium]: size === "medium",
					[s.large]: size === "large",
					[s.xlarge]: size === "xlarge",
					[s.xxlarge]: size === "xxlarge",
					[s.avatarBorder]: withBorder,
					[s.link]: link,
					[s.isSafari]: isSafari,
				})}
			>
				{photo ? (
					<Image
						loader={() => {
							return photo;
						}}
						unoptimized
						src={photo}
						layout="fill"
						objectFit="cover"
						alt="Аккаунт"
					/>
				) : (
					<div className={s.noPhoto}>
						<p>{initials || ""}</p>
					</div>
				)}
			</div>
		);
	};
	return link ? <Link href={link}>{renderAvatar()}</Link> : renderAvatar();
};

export default Avatar;
