import type { AppContext, AppProps } from "next/app";
import App from "next/app";
import "../styles/index.sass";
import React, { ReactElement, ReactNode } from "react";
import { NextPage } from "next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dynamic from "next/dynamic";
import { Provider } from "effector-react";
import { useScope } from "@hooks/useScope";
import { AuthWrapper } from "@shared/wrappers/AuthWrapper";
import RouterProvider from "@shared/wrappers/RouterWrapper";
import { allSettled, fork, serialize } from "effector";
import AppLayout from "src/containers/AppLayout";
import Head from "next/head";
import WebAnalytics from "../features/WebAnalytics";
import "../config/entryPointEffector";
import { getCookie } from "cookies-next";
import { setIsAuth } from "../process/authProcess/model/public";
import { ACCESS_TOKEN_KEY } from "../process/authProcess/model/constants";
import { getAllCategoriesFx } from "@models/categories/getAllCategories";
import { initFooterData } from "@features/Footer/model/public";

type InitialState = Record<string, unknown>;

const DynamicMetrics = dynamic(
	() => {
		return import("@components/base/Metrics");
	},
	{
		ssr: false,
	}
);

export type NextPageWithLayout<T = unknown> = NextPage<T> & {
	getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout<T> = AppProps & {
	Component: NextPageWithLayout;
	pageProps: T;
	customAppProps: Record<string, unknown>;
};

function MyApp({ Component, pageProps, customAppProps }: AppPropsWithLayout<InitialState>) {
	const pageInitialState = typeof pageProps.initialState === "object" ? pageProps.initialState : {};
	const initialProps: InitialState = {
		...customAppProps,
		...pageInitialState,
	};
	const scope = useScope(initialProps);
	const getLayout =
		Component.getLayout ??
		((page) => {
			return page;
		});

	const ComponentWithLayout =
		Component.getLayout !== undefined ? (
			getLayout(<Component {...pageProps} />)
		) : (
			<AppLayout>
				{" "}
				<Component {...pageProps} />{" "}
			</AppLayout>
		);

	return (
		<WebAnalytics>
			<Provider key={"effector"} value={scope}>
				<Head>
					<title>Selfschool — онлайн-школа, образовательная платформа</title>
					{/*query param "?v=2" force download without cache*/}
					<link rel="icon" type="image/x-icon" href="/favicon.ico?v=2" />
				</Head>
				<RouterProvider />
				<DynamicMetrics />
				<ToastContainer />
				<AuthWrapper>{ComponentWithLayout}</AuthWrapper>
			</Provider>
		</WebAnalytics>
	);
}

MyApp.getInitialProps = async (context: AppContext) => {
	const ctx = await App.getInitialProps(context);
	const scope = fork();
	const { req, res } = context.ctx;

	const accessToken = getCookie(ACCESS_TOKEN_KEY, {
		req,
	});

	if (req && res) {
		await allSettled(setIsAuth, {
			scope,
			params: !!accessToken,
		});

		await allSettled(initFooterData, {
			scope,
		});

		await allSettled(getAllCategoriesFx, {
			scope,
			params: {
				withCoursesOnly: true,
			},
		});
	}

	return {
		...ctx,
		customAppProps: serialize(scope),
	};
};

export default MyApp;
