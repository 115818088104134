// Функция, возвращающая GET-параметры (query), разделенные через амперсанд
const getParsedQuery = (
	queries: Record<string, Array<string> | number | string | undefined | boolean | null>
): string => {
	const definedQueries: Array<string> = [];

	for (const [key, value] of Object.entries(queries)) {
		if (Array.isArray(value) && value.length > 0) {
			let arrayOfQuery = `${key}=`;

			for (let i = 0; i < value.length; i++) {
				arrayOfQuery += `${value[i]}`;

				if (i !== value.length - 1) {
					arrayOfQuery += ",";
				}
			}

			definedQueries.push(arrayOfQuery);
		}

		if (!Array.isArray(value) && value !== undefined && value !== "" && value !== null) {
			definedQueries.push(`${key}=${value.toString()}`);
		}
	}

	if (definedQueries.length === 0) {
		return "";
	}

	const queryString = `${definedQueries.join("&")}`;

	return queryString;
};

export default getParsedQuery;
