import { attach, createEvent, createStore, sample } from "effector";
import { NextRouter } from "next/router";

const attachRouterEv = createEvent<NextRouter | null>();
const $router = createStore<NextRouter | null>(null);

$router.on(attachRouterEv, (_, router) => {
	return router;
});

const goToRouteEv = createEvent<string>();

const goToRouteFx = attach({
	source: $router,
	effect: (router, param) => {
		return router && router.asPath !== param && router.push(param);
	},
});

sample({
	clock: goToRouteEv,
	target: goToRouteFx,
});

export { $router, attachRouterEv, goToRouteFx };
