import { ProfileInfo } from "../../../../../services/api/user/typedef";

export const fileLoaderDescription = [
	"Загрузите изображение своего профиля здесь.",
	" Важные требования: не более 40 Мб",
	"jpg, jpeg, png, без текста на изображении.",
];

export const initialProfileInfo: ProfileInfo = {
	id: NaN,
	firstName: "",
	site: "",
	lastName: "",
	competence: "",
	photo: "",
	bio: "",
};
