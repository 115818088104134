import { DataLayerItemType, Product, WebAnalytics } from "./webAnalytics";

type YandexMetricaInit = [
	number,
	"init",
	{
		clickmap: boolean;
		trackLinks: boolean;
		accurateTrackBounce: boolean;
		webvisor: boolean;
		ecommerce: boolean;
	}
];
type YandexMetricaPageView = [number, "hit", string, { title?: string }];
type YandexReachGoal = [number, "reachGoal", string];

type YandexMetricaEvent = YandexMetricaInit | YandexMetricaPageView | YandexReachGoal;

interface YandexMetricObjectWithWindow extends Window {
	ym: {
		a: YandexMetricaEvent[];
		l: number;
	};
	dataLayer: Array<DataLayerItemType>;
}

declare let window: YandexMetricObjectWithWindow;

export const createYandexMetrikaClient = (id: number): WebAnalytics => {
	if (typeof window !== "undefined" && window.ym === undefined) {
		window.ym = {
			a: [],
			l: Date.now(),
		};
		window.ym.a.push([
			id,
			"init",
			{
				clickmap: true,
				trackLinks: true,
				accurateTrackBounce: true,
				webvisor: true,
				ecommerce: true,
			},
		]);
		window.dataLayer = window.dataLayer || [];
	}

	return {
		pageView: (url) => {
			window.ym.a.push([id, "hit", url.toString().replace(url.origin, ""), {}]);
		},
		viewProduct: (product) => {
			window.dataLayer.push({
				ecommerce: {
					currencyCode: "RUB",
					detail: {
						products: [product],
					},
				},
			});
		},
		addProductToCart: (product) => {
			window.dataLayer.push({
				ecommerce: {
					currencyCode: "RUB",
					add: {
						products: [product],
					},
				},
			});
		},
		buyProduct: (product, id) => {
			window.dataLayer.push({
				ecommerce: {
					currencyCode: "RUB",
					purchase: {
						actionField: {
							id: id,
						},
						products: [product],
					},
				},
			});
		},
		clickProduct: (product: Product) => {
			window.dataLayer.push({
				ecommerce: {
					currencyCode: "RUB",
					click: {
						products: [product],
					},
				},
			});
		},
		promoClick: (promo) => {
			window.dataLayer.push({
				ecommerce: {
					currencyCode: "RUB",
					promoClick: {
						promotions: [promo],
					},
				},
			});
		},
		promoView: (promo) => {
			window.dataLayer.push({
				ecommerce: {
					currencyCode: "RUB",
					promoView: {
						promotions: [promo],
					},
				},
			});
		},
		removeProductFromCart: (product) => {
			window.dataLayer.push({
				ecommerce: {
					currencyCode: "RUB",
					remove: {
						products: [product],
					},
				},
			});
		},
		reachGoal: (goal) => {
			window.ym.a.push([id, "reachGoal", goal]);
		},
	};
};
