import { createEffect, sample } from "effector";
import { PhoneRequestDto } from "@generated/models/PhoneRequestDto";
import AuthService from "../../../services/api/auth/AuthService";
import { AxiosError } from "axios";
import { API_ERROR } from "../../../constants/API_ERROR";
import { showErrorToast } from "@models/toast";

export const addPhoneNumberFx = createEffect(async (requestData: PhoneRequestDto) => {
	return AuthService.addPhoneNumber(requestData);
});

sample({
	clock: addPhoneNumberFx.failData,
	fn: (error) => {
		if (error instanceof AxiosError && error.response?.data.type === API_ERROR.entityAlreadyExist) {
			return "Этот телефон уже используется в систем, попробуйте ввести другой";
		}
		return "Произошла ошибка во время добавления номера телефона";
	},
	target: showErrorToast,
});
