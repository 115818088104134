import { $api } from "../index";
import { routes } from "../routes";
import getParsedQuery from "@shared/functions/getParsedQuery";
import {
	AddAudienceInfoBody,
	AddCourseInfoBody,
	AddPriceBody,
	AddReviewRequestBody,
	AddReviewResponse,
	AuthorInfo,
	CourseReportBody,
	CourseResponse,
	CoursesList,
	CreateSectionBody,
	DeleteSectionParams,
	GetBestCoursesParams,
	GetConstructorCourseParams,
	GetCoursesByCoursesIdsParams,
	GetCoursesByIdsResponse,
	GetCoursesParams,
	GetInstructorCoursesParams,
	GetReviewsParams,
	ReviewsListResponse,
	SectionResponse,
	UpdateConstructorInfoCourseParams,
	UpdateSectionBody,
	UpdateSectionParams,
} from "./typedef";

class CoursesService {
	static async getCourses(params: GetCoursesParams) {
		const queryParams = getParsedQuery(params);
		return await $api.get<CoursesList>(`${routes.courses}/filter?${queryParams}`);
	}

	static async courseReport(courseId: number, values: CourseReportBody) {
		return await $api.post<void>(`${routes.courses}/${courseId}/report`, values);
	}

	static async getBestCourses(params: GetBestCoursesParams) {
		const queryParams = getParsedQuery(params);

		return await $api.get<CoursesList>(`${routes.courses}/best?${queryParams}`);
	}

	static async deleteCourse(courseId: number) {
		return await $api.delete<void>(`${routes.courses}/${courseId}`);
	}

	static async getCourse(courseUrl: string) {
		return await $api.get<CourseResponse>(`${routes.courses}/${courseUrl}/`);
	}

	static async getReviews({ limit, id, sort, page }: GetReviewsParams) {
		const queryParams = getParsedQuery({
			limit,
			sort,
			page,
		});
		return await $api.get<ReviewsListResponse>(`${routes.courses}/${id}/reviews?${queryParams}`);
	}

	static async addReview({ courseId, rating, content }: AddReviewRequestBody) {
		return await $api.post<AddReviewResponse>(`${routes.courses}/${courseId}/reviews`, {
			rating,
			content,
		});
	}

	static async deletePromoVideo(courseId: number) {
		return await $api.delete<void>(`${routes.courses}/delete-promo/${courseId}`);
	}

	static async getInstructorCourses(params: GetInstructorCoursesParams) {
		const queryParams = getParsedQuery(params);

		return await $api.get<CoursesList>(`${routes.usersMeCreatedCourses}?${queryParams}`);
	}

	static async createCourse() {
		return await $api.post<CourseResponse>(`${routes.courses}`);
	}

	static async getCoursesByCoursesIds({ coursesIds }: GetCoursesByCoursesIdsParams) {
		return await $api.get<GetCoursesByIdsResponse>(`${routes.courses}?ids=${coursesIds}`);
	}

	static async getCourseAuthorInfoById(id: number) {
		return await $api.get<AuthorInfo>(`${routes.users}/${id}/author-info`);
	}

	// Модерация
	static async sendToModeration(courseId: number) {
		return await $api.post(`/moderation/courses/${courseId}/review`);
	}

	static async moderatorPublish(courseId: number) {
		return await $api.post(`/moderation/courses/${courseId}/publish`);
	}

	static async moderatorReject(courseId: number, reason: string | null) {
		return await $api.post(`/moderation/courses/${courseId}/reject`, {
			reason,
		});
	}

	static async moderatorAssignTagToCourse(courseId: number, tagId: number) {
		return await $api.post<CourseResponse>(`/moderation/courses/${courseId}/tags/${tagId}`);
	}

	static async moderatorUnassignTagToCourse(courseId: number, tagId: number) {
		return await $api.delete<CourseResponse>(`/moderation/courses/${courseId}/tags/${tagId}`);
	}

	// Конструктор

	static async getConstructorCourse({ id }: GetConstructorCourseParams) {
		return await $api.get<CourseResponse>(`${routes.courses}/${id}/edit`);
	}

	static async saveInfo({ courseId, form }: UpdateConstructorInfoCourseParams & { form: AddCourseInfoBody }) {
		return await $api.post<CourseResponse>(`${routes.courses}/${courseId}/info`, form);
	}

	static async savePrice({ courseId, form }: UpdateConstructorInfoCourseParams & { form: AddPriceBody }) {
		return await $api.post<CourseResponse>(`${routes.courses}/${courseId}/price`, form);
	}

	static async saveAudience({
		courseId,
		form,
	}: UpdateConstructorInfoCourseParams & { form: AddAudienceInfoBody }) {
		return await $api.post<CourseResponse>(`${routes.courses}/${courseId}/audience`, form);
	}

	static async createSection({ courseId, title }: CreateSectionBody & UpdateConstructorInfoCourseParams) {
		return await $api.post<SectionResponse>(`${routes.courses}/${courseId}/sections`, {
			title,
		});
	}

	static async changeSection({ sectionId, courseId, title, order }: UpdateSectionBody & UpdateSectionParams) {
		return await $api.put<SectionResponse>(`${routes.courses}/${courseId}/sections/${sectionId}`, {
			title,
			order,
		});
	}

	static async deleteSection({ sectionId, courseId }: DeleteSectionParams) {
		return await $api.delete<void>(`${routes.courses}/${courseId}/sections/${sectionId}`);
	}
}

export default CoursesService;
