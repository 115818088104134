import { AuthProcess } from "./typedef";

export const ACCESS_TOKEN_KEY = "access_token";
export const REFRESH_TOKEN_KEY = "refresh_token";

export const AUTH_PROCESS_DEFAULT_STATE: AuthProcess = {
	challenge: null,
	requirements: null,
	stage: null,
	confirmPhoneStage: {
		phone: null,
	},
	verifyPhoneStage: {
		verificationCode: null,
	},
	registrationStage: {
		userEmail: null,
	},
	addPhoneStage: {
		sendCodeCounter: 0,
	},
	restorePasswordStage: {
		confirmationEmailCounter: 0,
	},
};
