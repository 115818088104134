import { createEffect } from "effector";
import { GetDialogOfGenerationsListParams } from "@services/api/dialogOfGenerations/typedef";
import DialogOfGenerationsService from "@services/api/dialogOfGenerations/dialogOfGenerationsService";

export const getDialogOfGenerationsThemesFx = createEffect(
	async ({ page }: GetDialogOfGenerationsListParams) => {
		const { data } = await DialogOfGenerationsService.getThemes({
			page,
		});
		return data;
	}
);
