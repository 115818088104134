import { createEvent } from "effector";
import { ProfileChangePhotoBody, AuthUserResponse } from "src/services/api/user/typedef";
import { authenticationPublicApi } from "../../../process/authProcess/model/public";

/*======== stores ======== */
export const $authedUser = authenticationPublicApi.store<AuthUserResponse | null>(null);

/*======== events ======== */

export const updateAuthedUserPhotoEvent = createEvent<ProfileChangePhotoBody>();
