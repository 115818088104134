export enum STATUS {
	DONE = "done",
	FAIL = "fail",
}

export enum UPLOAD_FILE_STATUSES {
	// local statuses
	DELIVERED = "delivered",
	DELIVERING = "delivering",
	NOT_DELIVERED = "not_delivered",

	// server statuses
	UPLOADING = "uploading",
	SUCCESS = "upload_success",
	FAIL = "upload_failed",
	NOT_ADDED = "not_added",
}

export enum USER_STATUSES {
	DELETED = "pending_deletion",
}

export enum VIDEO_STATUSES {
	NOT_ADDED = "not_uploaded",
	UPLOADING = "in_progress",
	UPLOAD_SUCCESS = "success",
	UPLOAD_FAILED = "failure",
}
