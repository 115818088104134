import React, { FC } from "react";
import { LinkProps } from "next/dist/client/link";
import NextJSLink from "next/link";

const NextLink: FC<JSX.IntrinsicElements["a"] & React.PropsWithChildren<LinkProps>> = (props) => {
	const passedProps: Omit<React.PropsWithChildren<LinkProps>, "onClick"> = {
		passHref: true,
		prefetch: false,
		scroll: true,
		...props,
	};

	const { className, style, target } = props;

	return (
		<NextJSLink {...passedProps} onClick={undefined} className={props.className}>
			{/*eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
			<a onClick={props.onClick} className={className} style={style} target={target}>
				{props.children}
			</a>
		</NextJSLink>
	);
};
export default NextLink;
