import { toast } from "react-toastify";
import { createEvent, createEffect, sample } from "effector";

type ShowToastParams = { message: string; type: "success" | "error" };

const SUCCESS = "success" as const;
const ERROR = "error" as const;

export const showSuccessToast = createEvent<string>();
export const showErrorToast = createEvent<string>();

const showToastFx = createEffect<ShowToastParams, void, Error>(({ message, type }) => {
	toast[type](message);
});

sample({
	clock: showSuccessToast,
	target: showToastFx,
	fn: (message) => {
		return {
			type: SUCCESS,
			message,
		};
	},
});

sample({
	clock: showErrorToast,
	target: showToastFx,
	fn: (message) => {
		return {
			type: ERROR,
			message,
		};
	},
});
