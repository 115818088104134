import styles from "./styles.module.css";
import { DOCUMENT_LINKS } from "src/constants/DOCUMENTS_LINKS";

export const additionalInfoLinks = [
	{
		label: "Политика обработки персональных данных",
		url: DOCUMENT_LINKS.PRIVACY_URL,
	},

	{
		label: "Согласие на обработку персональных данных",
		url: DOCUMENT_LINKS.PERSONAL_DATA_AGREEMENT,
	},
	{
		label: "Пользовательское соглашение",
		url: DOCUMENT_LINKS.USER_AGREEMENT_URL,
	},

	{
		label: "Согласие на использование cookies-файлов",
		url: DOCUMENT_LINKS.COOKIES_URL,
	},
];
const FooterRights = () => {
	const email = "info@selfschool.ru";
	return (
		<div className={styles.container}>
			<div className={styles.companyInfo}>
				<span>© ООО «Парта», 2021-2024</span>
				<a target="_blank" href={`mailto:${email}`} rel="noreferrer">
					{email}
				</a>
			</div>
			<div className={styles.termsOfUse}>
				{additionalInfoLinks.map(({ url, label }) => {
					return (
						<a target="_blank" key={url} href={url} rel="noreferrer">
							{label}
						</a>
					);
				})}
			</div>
		</div>
	);
};

export default FooterRights;
