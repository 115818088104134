import { createEffect, sample } from "effector";
import { PhoneConfirmRequestDto } from "@generated/models/PhoneConfirmRequestDto";
import AuthService from "../../../services/api/auth/AuthService";
import { AxiosError } from "axios";
import { showErrorToast } from "@models/toast";
import { goToRouteFx } from "@models/router";
import { ROUTES } from "../../../constants/ROUTES";
import { registerConfirmFx } from "@models/auth/activateToken";

export const confirmPhoneNumberFx = createEffect(async (requestData: PhoneConfirmRequestDto) => {
	const { data } = await AuthService.confirmPhone(requestData);

	return data;
});

sample({
	clock: registerConfirmFx.failData,
	fn: (error) => {
		if (error instanceof AxiosError) {
			showErrorToast(error.response?.data.message ?? "Неизвестная ошибка");
		}
		goToRouteFx(ROUTES.REGISTRATION);
	},
});
