import clsx from "clsx";
import styles from "./styles.module.sass";
import NextLink from "@shared/wrappers/NextLink";

type CustomLinkVariant = "primaryFilled" | "secondaryFilled" | "tertiary" | "secondaryOutlined";
type CustomLinkSize = "small" | "header" | "firstSection" | "large";

type CustomLinkProps = {
	variant?: CustomLinkVariant;
	href: string;
	size?: CustomLinkSize;
	text: string;
	className?: HTMLAnchorElement["className"];
};

const CustomLink = ({ variant = "primaryFilled", size, href, text, className }: CustomLinkProps) => {
	const variantClass = clsx({
		[styles.primaryFilled]: variant === "primaryFilled",
		[styles.secondaryOutlined]: variant === "secondaryOutlined",
		[styles.secondaryFilled]: variant === "secondaryFilled",
		[styles.tertiary]: variant === "tertiary",
	});

	const sizeClass = clsx({
		[styles.small]: size === "small",
		[styles.header]: size === "header",
		[styles.firstSection]: size === "firstSection",
	});

	return (
		<NextLink className={clsx(styles.wrapper, variantClass, sizeClass, className)} href={href}>
			{text}
		</NextLink>
	);
};

export default CustomLink;
