import { createDomain } from "effector";
import { ShoppingCart } from "@features/ShoppingChart/model/typedef";

export const shoppingCartApi = createDomain("shopping-cart");

/* STORES */
export const $shoppingCart = shoppingCartApi.store<ShoppingCart | null>(null);

/* EVENTS */
export const resetShoppingCart = shoppingCartApi.event<void>();
/**
 *  Флоу НЕ авторизованного пользователя
 */
export const addToCartNonAuthed = shoppingCartApi.event<number>();
export const removeFromCartNonAuthed = shoppingCartApi.event<number>();

/**
 *  Флоу авторизованного пользователя
 */
export const removeFromCartAuthed = shoppingCartApi.event<number>();

export const getCartForUnAuthedUser = shoppingCartApi.event<number[]>();
