import { createEffect, sample } from "effector";
import AuthService from "../../../services/api/auth/AuthService";
import { AxiosError } from "axios";
import { showErrorToast } from "@models/toast";
import { LoginRequestDto } from "@generated/models/LoginRequestDto";
import { AuthChallengeResponseViewModel } from "@services/api/auth/typedef";
import { ValidationExceptionDto } from "@generated/models/ValidationExceptionDto";

export const loginFx = createEffect<
	LoginRequestDto,
	AuthChallengeResponseViewModel,
	AxiosError<ValidationExceptionDto>
>(async (params) => {
	const { data } = await AuthService.login(params);

	return data;
});

sample({
	source: loginFx.failData,
	target: showErrorToast,
	fn: (e) => {
		if (e instanceof AxiosError<ValidationExceptionDto>) {
			return `Не удалось авторизоваться. ${e.response?.data.message}`;
		}

		return "Неизвестная ошибка, попробуте позже";
	},
});
