import Image from "next/image";
import cartSrc from "../../../../../../public/icons/cart-white.svg";
import styles from "./style.module.sass";
import { ROUTES } from "../../../../../constants/ROUTES";
import NextLink from "@shared/wrappers/NextLink";
import { useShoppingCart } from "@features/ShoppingChart/hooks/useShoppingCart";

type CartWithCounterProps = {
	isAuth: boolean;
};

const CartWithCounter = ({ isAuth }: CartWithCounterProps) => {
	const { cartProductsAmount } = useShoppingCart({
		isAuth,
	});

	return (
		<NextLink href={ROUTES.CART} className={styles.cart}>
			<Image src={cartSrc} layout="fill" objectFit="contain" alt="Корзина" />
			{!!cartProductsAmount && <div className={styles.itemsToBuy}>{cartProductsAmount}</div>}
		</NextLink>
	);
};

export default CartWithCounter;
