var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"mYIdsC4dKW4dls-8tP-Je"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
	dsn: SENTRY_DSN || "https://a4330f1f5335482daee3412028bf7e17@o236525.ingest.sentry.io/4504768829259776",
	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 1.0,
	environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
	ignoreUrls: "localhost",
	denyUrls: [/https?:\/\/mc\.yandex\.ru.*/gm],
	// ...
	// Note: if you want to override the automatic release value, do not set a
	// `release` value here - use the environment variable `SENTRY_RELEASE`, so
	// that it will also get attached to your source maps
});
