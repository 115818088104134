import { $api } from "../index";
import { routes } from "../routes";
import getParsedQuery from "@shared/functions/getParsedQuery";

import {
	AddCourseToFavouriteParams,
	AuthUserResponse,
	ChangeEmailRequest,
	ChangePasswordRequest,
	CreateNoteForCourseParams,
	FavouritesGetResponse,
	GetInstructorInfoParams,
	GetInstructorResponse,
	GetQuestionsForCreatedCourseParams,
	GetReviewsForCreatedCourseParams,
	RemoveCourseToFavouriteParams,
} from "./typedef";
import { COURSES_LIMIT } from "../../../constants/common";
import { CoursesList, ReviewsListResponse } from "@services/api/courses/typedef";

import { GetPurchasedCourseNotesParams } from "@models/users/getPurchasedCourseNotes";
import { NotesListResponse } from "@features/Notes/model/typedef";
import { ConfirmEmailChangeRequestDto } from "@generated/models/ConfirmEmailChangeRequestDto";
import { QuestionsPagination } from "../lectures/typedef";

class UserService {
	static async getCurrentUserAccountInfo() {
		return await $api.get<AuthUserResponse>(`${routes.usersMe}`);
	}

	static async changeUserEmail(requestBody: ChangeEmailRequest) {
		return await $api.put<void>(`${routes.usersMe}/email`, requestBody);
	}

	static async confirmOldEmail(requestBody: ConfirmEmailChangeRequestDto) {
		return await $api.post<void>(`${routes.usersMe}/email/confirm-old`, requestBody);
	}

	static async confirmNewEmail(requestBody: ConfirmEmailChangeRequestDto) {
		return await $api.post<void>(`${routes.usersMe}/email/confirm-new`, requestBody);
	}

	static async savePassword(requestBody: ChangePasswordRequest) {
		return await $api.put<void>(`${routes.usersMe}/password`, requestBody);
	}

	static async getFavouriteCourses(limit = COURSES_LIMIT) {
		return await $api.get<CoursesList>(`${routes.usersMe}/favourites?page=1&limit=${limit}`);
	}

	static async addToFavourites({ courseId }: AddCourseToFavouriteParams) {
		return await $api.post<FavouritesGetResponse>(`${routes.usersMe}/favourites/${courseId}`);
	}

	static async removeFromFavourites({ courseId }: RemoveCourseToFavouriteParams) {
		return await $api.delete<FavouritesGetResponse>(`${routes.usersMe}/favourites/${courseId}`);
	}

	static async getInstructorInfo({ instructorId }: GetInstructorInfoParams) {
		return await $api.get<GetInstructorResponse>(`${routes.users}/${instructorId}/author-info`);
	}

	static async deleteAccount() {
		return await $api.delete<void>(`${routes.usersMe}`);
	}

	static async restoreAccount() {
		return await $api.patch<AuthUserResponse>(`${routes.usersMe}/restore-account`);
	}

	static async getCreatedCourses() {
		return await $api.get<CoursesList>(`${routes.usersMeCreatedCourses}`);
	}

	static async getQuestionsForCreatedCourse(params: GetQuestionsForCreatedCourseParams) {
		const queries = getParsedQuery(params);
		return await $api.get<QuestionsPagination>(`${routes.usersMeCreatedCourses}/questions?${queries}`);
	}

	static async getReviewsForCreatedCourse(params: GetReviewsForCreatedCourseParams) {
		const queries = getParsedQuery(params);

		return await $api.get<ReviewsListResponse>(`${routes.usersMeCreatedCourses}/reviews?${queries}`);
	}

	static async getPurchasedCourseNotes(params: GetPurchasedCourseNotesParams) {
		return await $api.get<NotesListResponse>(`${routes.notes}/${params.courseId}?sort=${params.sort}`);
	}

	static async createNoteForPurchasedCourse(params: CreateNoteForCourseParams) {
		return await $api.post<NotesListResponse>(`${routes.notes}/${params.courseId}`, {
			...params,
			courseId: undefined,
		});
	}
}

export default UserService;
