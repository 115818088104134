import { sample } from "effector";
import { loginFx } from "@models/auth/login";
import { callUseChallengeFX, checkAuthRequirementsFx } from "../private";
import { useChallengeFx } from "@models/auth/useChallenge";

sample({
	clock: loginFx.doneData,

	target: checkAuthRequirementsFx,
});

sample({
	clock: useChallengeFx.done,
	fn: () => {
		const challengeFlow = "login" as const;
		return {
			challengeFlow,
		};
	},
	target: callUseChallengeFX,
});
