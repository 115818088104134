import { useAcceptCookie } from "@hooks/useAcceptCookie";
import clsx from "clsx";
import Image from "next/image";
import { DOCUMENT_LINKS } from "src/constants/DOCUMENTS_LINKS";
import cookieIcon from "../../../../public/icons/cookie.svg";
import styles from "./styles.module.sass";

const CookiesBanner = () => {
	const { isCookieAccepted, acceptCookie } = useAcceptCookie();
	return (
		<div className={clsx(styles.wrapper, isCookieAccepted && styles.hidden)}>
			<div className={styles.container}>
				<div className={styles.imageWrapper}>
					<Image src={cookieIcon} alt="cookieIcon" width="54.876px" height="52.502px" />
				</div>
				<p className={styles.text}>
					На сайте используются cookies-файлы, пиксели Вконтакте и данные от Яндекс.Метрики. Это позволяет нам
					анализировать взаимодействие посетителей с сайтом и делать его лучше. Продолжая пользоваться сайтом,
					вы даете{" "}
					<a href={DOCUMENT_LINKS.COOKIES_URL} className={styles.link} target="_blank" rel="noreferrer">
						согласие на использование cookies-файлов
					</a>{" "}
					и др. данных в рамках условий{" "}
					<a href={DOCUMENT_LINKS.PRIVACY_URL} className={styles.link} target="_blank" rel="noreferrer">
						Политики обработки персональных данных.
					</a>
				</p>
			</div>
			<button className={styles.button} onClick={acceptCookie}>
				Согласен
			</button>
		</div>
	);
};

export default CookiesBanner;
