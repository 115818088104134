import React, { useState } from "react";
import NextLink from "@shared/wrappers/NextLink";
import Image from "next/image";
import logo from "../../../../../../public/icons/logo_header_mobile.svg";
import FavoriteIcon from "../../../../FavouriteCourses/view/entries/FavoriteIcon";
import menuSrc from "../../../../../../public/icons/menu-white.svg";
import styles from "./MobileHeader.module.sass";
import dynamic from "next/dynamic";
import MobileSearch from "../../parts/MobileSearch";
import { HeaderProps } from "../../Header.typedef";
import CartWithCounter from "../../../../ShoppingChart/view/entries/CartWithCounter";
import { MenuLink } from "@features/AccountPopoverWithCategories/model/typedef";
import { useStore } from "effector-react";
import { $isAuth } from "../../../../../process/authProcess/model/public";

type MobileHeaderProps = HeaderProps;

const MobileMenu = dynamic(
	() => {
		return import("src/features/AccountPopoverWithCategories/view");
	},
	{
		ssr: false,
	}
);

export const MobileHeader = ({
	categories,
	searchValue,
	onSearchFormSubmit,
	onChangeSearchValue,
}: MobileHeaderProps) => {
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
	const isAuth = useStore($isAuth);

	const links: Array<MenuLink> = categories.map((category) => {
		return {
			title: category.title,
			url: `/${category.url}`,
		};
	});

	const onOpenMenu = () => {
		setIsMenuOpen(true);
	};

	const onCloseMenu = () => {
		setIsMenuOpen(false);
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.logoWrapper}>
				<NextLink href="/">
					<Image src={logo} alt="Selfschool" />
				</NextLink>
			</div>

			<div className={styles.actions}>
				<div className={styles.actionButtons}>
					<MobileSearch value={searchValue} onChange={onChangeSearchValue} onSubmit={onSearchFormSubmit} />
					<FavoriteIcon />
					<CartWithCounter isAuth={isAuth} />
					<button className={styles.burger} onClick={onOpenMenu}>
						<Image src={menuSrc} alt="Меню" height={12} width={20} />
					</button>
				</div>

				<MobileMenu isOpen={isMenuOpen} onClose={onCloseMenu} links={links} />
			</div>
		</div>
	);
};
