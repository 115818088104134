import { $api } from "../index";
import { routes } from "../routes";
import { CartWithCoursesResponse } from "./typedef";
import { CartGetResponseDto } from "@generated/models/CartGetResponseDto";

class CartService {
	static async getCart() {
		return await $api.get<CartGetResponseDto>(`${routes.cart}`);
	}

	static async addToCart(id: number) {
		return await $api.post<CartGetResponseDto>(`${routes.cart}/course/${id}`);
	}

	static async removeFromCart(id: number) {
		return await $api.delete<CartWithCoursesResponse>(`${routes.cart}/course/${id}`);
	}
}

export default CartService;
