import getParsedQuery from "@shared/functions/getParsedQuery";
import { $api } from "@services/api";
import { dialogOfGenerationsRoutes } from "@services/api/routes";
import {
	DialogOfGenerationsListResponse,
	GetDialogOfGenerationsListParams,
	HeadingResponse,
	ThemeResponse,
} from "@services/api/dialogOfGenerations/typedef";

class DialogOfGenerationsService {
	static async getHeadings(params: GetDialogOfGenerationsListParams) {
		const queryParams = getParsedQuery(params);
		return await $api.get<DialogOfGenerationsListResponse<HeadingResponse>>(
			`${dialogOfGenerationsRoutes.headings}?${queryParams}`
		);
	}

	static async getThemes(params: GetDialogOfGenerationsListParams) {
		const queryParams = getParsedQuery(params);
		return await $api.get<DialogOfGenerationsListResponse<ThemeResponse>>(
			`${dialogOfGenerationsRoutes.themes}?${queryParams}`
		);
	}
}

export default DialogOfGenerationsService;
