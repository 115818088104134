import { createEffect, createEvent, sample } from "effector";
import UserService from "../../../services/api/user/UserService";
import { RemoveCourseToFavouriteParams } from "src/services/api/user/typedef";

export const removeCourseFromFavouritesEvent = createEvent<RemoveCourseToFavouriteParams>();

export const removeCourseFromFavouritesFx = createEffect(
	async (requestBody: RemoveCourseToFavouriteParams) => {
		const { data } = await UserService.removeFromFavourites(requestBody);

		return data;
	}
);

sample({
	clock: removeCourseFromFavouritesEvent,
	target: removeCourseFromFavouritesFx,
});
