import styles from "./DesktopHeader.module.sass";
import NextLink from "@shared/wrappers/NextLink";
import { ROUTES } from "../../../../../constants/ROUTES";
import LogoDesktop from "../../../../../../public/icons/logo_header_desktop.svg";
import clsx from "clsx";
import CustomLink from "@components/UI/CustomLink";
import Image from "next/image";
import AvatarWithAccountInfo from "src/features/AvatarWithAccountInfo";
import FavoriteIcon from "../../../../FavouriteCourses/view/entries/FavoriteIcon";
import DesktopSearch from "src/features/Header/view/parts/DesktopSearch";
import { HeaderProps } from "../../Header.typedef";
import CartWithCounter from "../../../../ShoppingChart/view/entries/CartWithCounter";

type DesktopHeaderProps = HeaderProps & {
	isAuth: boolean;
	isUserDeleted: boolean;
};

export const linkText = "Поделиться знанием".toUpperCase();

export const DesktopHeader = ({
	categories,
	isAuth,
	isUserDeleted,
	onChangeSearchValue,
	searchValue,
	onSearchFormSubmit,
}: DesktopHeaderProps) => {
	const slicedCategories = categories.length > 5 ? categories.slice(1, 4) : categories;
	const hiddenSlicedCategories = categories.slice(1);
	const allCategoriesLink = categories[0];
	const showMoreButton = categories.length > 5;
	const isAuthedActualUser = isAuth && !isUserDeleted;

	return (
		<div className={styles.wrapper}>
			<div className={styles.logoAndNavigationWrapper}>
				<NextLink href={ROUTES.MAIN} className={styles.logoWrapper}>
					<Image src={LogoDesktop} alt="Selfschool" width={146} height={40} />
				</NextLink>

				<nav className={styles.navigation}>
					<NextLink className={styles.link} href={`/${allCategoriesLink?.url}`}>
						{allCategoriesLink?.title}
					</NextLink>

					<div className={styles.inlineCategoriesWrapper}>
						{slicedCategories.map((link) => {
							return (
								<NextLink href={`/${link.url}`} key={link.id} className={styles.link}>
									{link.title}
								</NextLink>
							);
						})}
					</div>

					{showMoreButton && (
						<div className={styles.more}>
							<span>ЕЩЁ</span>
							<div className={styles.expandIcon} />

							<div className={styles.hiddenMenu}>
								{hiddenSlicedCategories.map(({ url, title }, index) => {
									return (
										<div key={index} className={styles.hiddenLinkWrapper}>
											<NextLink href={`/${url}`} key={index} className={clsx(styles.link, styles.hiddenLink)}>
												{title}
											</NextLink>
										</div>
									);
								})}
							</div>
						</div>
					)}
				</nav>
			</div>

			<div className={styles.searchWrapper}>
				<DesktopSearch value={searchValue} onChange={onChangeSearchValue} onSubmit={onSearchFormSubmit} />
			</div>

			<div className={styles.authBlockWrapper}>
				{isAuthedActualUser ? (
					<div className={styles.authedUserBarWrapper}>
						<div className={styles.actionButtons}>
							<NextLink href={ROUTES.MY_EDUCATION_COURSES} className={styles.myEducationLink}>
								Мое обучение
							</NextLink>
							<FavoriteIcon />
							<CartWithCounter isAuth={isAuth} />
						</div>
						<AvatarWithAccountInfo />
					</div>
				) : (
					<div className={styles.unAuthedUserBarWrapper}>
						<CartWithCounter isAuth={isAuth} />
						<NextLink href={ROUTES.LOGIN} className={styles.link}>
							войти
						</NextLink>
						<NextLink href={ROUTES.REGISTRATION} className={styles.link}>
							регистрация
						</NextLink>
					</div>
				)}

				<CustomLink
					href={isAuth ? ROUTES.INSTRUCTOR_PANEL_COURSES : ROUTES.LOGIN}
					text={linkText}
					size={"header"}
				/>
			</div>
		</div>
	);
};
